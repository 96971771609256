import env from "@/config/env"
import Cookies from "js-cookie"
import { create } from "zustand"

export interface IUiStore {
    title: string
    favicon: string
    preferredLanguage: string
    showMenu: boolean
    currentPage: string
    previousPage: string
}

export interface IUiSetter {
    updateState: (update: Partial<IUiStore>) => void
    setShowMenu: (value: boolean) => void
}

const { resellerFavicon } = JSON.parse(Cookies.get(env.COOKIE_NAME) ?? "{}")

const initalState = {
    title: "",
    favicon: resellerFavicon,
    preferredLanguage: "",
    showMenu: false,
    currentPage: "",
    previousPage: ""
}

const UiStore = create<IUiStore & IUiSetter>((set) => ({
    ...initalState,
    updateState: (update) => set(() => ({ ...update })),
    setShowMenu: (showMenu) => set(() => ({ showMenu })),
}))

export default UiStore
