import BaseInput from "@/components/base/input";
import BaseSelect from "@/components/base/select";
import { Checkbox, FormControlLabel, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import PaperBlock from "../../base/paper-block";
import useInvoiceContact from "./useInvoiceContact";

const ResellerInvoiceContact = ({
  control,
  formState,
  formValue,
  handleUnlimited,
  handleLimit,
}) => {
  const { t } = useTranslation();
  const { currencies, paymentTypes } = useInvoiceContact();
  return (
    <PaperBlock title={t("RESELLERS.FACTURATIONS")} paperElevation={1}>
      <Stack flexDirection={"row"} gap={2}>
        <PaperBlock title={t("RESELLERS.INVOICE_CONTACT")} titleVariant="h3">
          <Stack flexDirection={"column"} gap={1}>
            <BaseSelect
              label={t("FORMS.CURRENCY")}
              options={currencies}
              controlName="currency_code"
              formStateError={!!formState?.errors["currency_code"]}
              required
              control={control}
            />
            <BaseSelect
              label={t("FORMS.PAYMENT_METHOD")}
              options={paymentTypes}
              controlName="payment_type"
              formStateError={!!formState?.errors["payment_type"]}
              required
              control={control}
            />
          </Stack>
        </PaperBlock>
        <PaperBlock title={t("RESELLERS.LICENCE_NUMBER")} titleVariant="h3">
          <Stack flexDirection={"column"} gap={1}>
            <FormControlLabel
              label={t("FORMS.UNLIMITED")}
              checked={formValue?.unlimited}
              control={<Checkbox onChange={handleUnlimited} />}
            ></FormControlLabel>
            <BaseInput
              type="number"
              error={!!formState?.errors["license_limit"]}
              disabled={formValue?.unlimited}
              InputProps={{
                inputProps: {
                  min: 0,
                },
              }}
              label={t("FORMS.LICENCE_LIMIT")}
              value={formValue?.license_limit}
              onChange={handleLimit}
              required
            />
            {/* <FormController
              fieldName="license_limit"
              control={control}
              type="number"
              fieldLabel={t("FORMS.LICENCE_LIMIT")}
              disabled={formValue?.unlimited}
            /> */}
          </Stack>
        </PaperBlock>
      </Stack>
    </PaperBlock>
  );
};

export default ResellerInvoiceContact;
