import {
  CreateCompanyPayload,
  LicencesType,
  NetworkType,
} from "@/api/companies";
import { create } from "zustand";

export interface ICompanyStore {
  disabledFields: NetworkType | null;
  companyData: CreateCompanyPayload | null;
  isLoading: boolean;
  conflictedFields: string[];
  prices: LicencesType[];
}

export interface ICompanySetter {
  updateState?: (update: Partial<ICompanyStore>) => void;
}

const initalState = {
  disabledFields: null,
  isLoading: false,
  companyData: null,
  conflictedFields: [],
  prices: [],
};

const CompanyStore = create<ICompanyStore & ICompanySetter>((set) => ({
  ...initalState,
  updateState: (update) => set(() => ({ ...update })),
}));

export default CompanyStore;
