import TitleWrapper from "@/components/base/page-container/title-wrapper";
import FormCompany from "@/components/forms/company";
import CompanyStore from "@/store/company";
import UiStore from "@/store/ui";
import { Stack } from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const CompaniesEdit = () => {
  const { updateState } = UiStore();
  const navigate = useNavigate();
  const { companyData } = CompanyStore();
  useEffect(() => {
    updateState({ title: companyData?.name ?? "" });
  }, [companyData]);
  return (
    <>
      <TitleWrapper title={companyData?.name ?? ""} handlePreviousButton={() => navigate("/accounts")} />
      <FormCompany />
    </>
  );
};

export default CompaniesEdit;
