import { CookiesService } from "@/services";
import { CountryCode } from "libphonenumber-js";
import { ApiService, DispatchrService } from "../";

interface OptionalResellerType {
  companyPhoneCountry: CountryCode;
  supportPhoneCountry: CountryCode;
  showUploadLogoWhite: boolean;
  showUploadLogoDark: boolean;
  showUploadFavicon: boolean;
  unlimited: boolean;
  uid: string;
}
export interface ResellerType extends Partial<OptionalResellerType> {
  name: string;
  cgv_url: string;
  address: string;
  city: string;
  zip: string;
  province_code: string;
  country_code: CountryCode;
  currency_code: string;
  license_limit: number;
  payment_type: number;
  logo: File | string;
  logo_dark: File | string;
  favicon: File | string;
  sms_sender: string;
  email_spotconnect: string;
  url_white_label: string;
  email_support: string;
  phone_support: string;
  url_support: string;
  user_email: string;
  user_firstname: string;
  user_lastname: string;
  lang: string;
  business_phone: string;
}

class ResellerApi {
  fetchResellers = async ({ page_number, page_size, user_uid }) => {
    return await ApiService.get(`/resellers`, {
      user_uid,
      page_number,
      page_size,
    });
  };

  fetchResellerInfos = async (hostname: string) => {
    return await ApiService.get(`/reseller_infos`, {
      hostname,
      reseller_uid: CookiesService.get("resellerUid"),
    });
  };

  fetchResellerCompanies = async ({ page_number, page_size, user_uid }) => {
    return await ApiService.get(`/companies`, {
      user_uid,
      page_number,
      page_size,
      reseller_uid: CookiesService.get("resellerUid"),
    });
  };

  fetchResellerBusinesses = async ({ page_number, page_size, user_uid }) => {
    return await ApiService.get(`/locations/company`, {
      page_number,
      page_size,
      user_uid,
      reseller_uid: CookiesService.get("resellerUid"),
    });
  };

  createCompany = async ({ company, user_uid }) => {
    const body = {
      user_uid,
      reseller_uid: CookiesService.get("resellerUid"),
      ...company,
    };
    return await ApiService.post(`/company`, body);
  };

  fetchCompanyInfo = async ({ id, user_uid }) => {
    return await ApiService.get(`/company/${id}`, {
      user_uid,
    });
  };

  getResellerInvoiceSettings = async () => {
    return await ApiService.get("/reseller-invoice-settings");
  };

  postFile: any = async ({ reseller_id, file }) => {
    return await DispatchrService.postFile(`/upload`, file, {
      reseller_id,
    });
  };

  createReseller = async ({ body }: { body: ResellerType }) => {
    const formData = new FormData();
    for (let key in body) {
      formData.append(key, body[key]);
    }
    return await ApiService.post(
      `/reseller?user_uid=${CookiesService.get("user_uid")}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  };

  updateReseller = async ({ body }: { body: ResellerType }) => {
    const uid = body?.uid;
    delete body?.uid;
    const formData = new FormData();
    for (let key in body) {
      formData.append(key, body[key]);
    }
    return await ApiService.post(
      `/reseller/${uid}?user_uid=${CookiesService.get("user_uid")}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  };

  getReseller = async (id: string) => {
    return await ApiService.get(
      `/reseller/${id}?user_uid=${CookiesService.get("user_uid")}`
    );
  };
}

export default new ResellerApi();
