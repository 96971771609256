import { ResellerType } from "@/api/reseller";
import AddLogoModal from "@/components/base/modal/add-logo-modal";
import { ResellerService } from "@/services";
import ResellerStore from "@/store/reseller";
import { Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import BaseAddressForm from "../base/address";
import PaperBlock from "../base/paper-block";
import useStyles from "../base/style";
import FormAction from "../form-action";
import ResellerInvoiceContact from "./invoice-contact";
import ResellerLogos from "./logos";
import ResellerMainContact from "./main-contact";
import ResellerMainInfos from "./main-infos";
import ResellerSupport from "./support";
import useResellerForm from "./useResellerForm";
import ResellerVisibleNames from "./visible-names";

const FormReseller = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const navigate = useNavigate();
  const { isLoading } = ResellerStore();
  const {
    formValue,
    formState,
    control,
    adminId,
    handleAddressChange,
    handleAddressInputValue,
    handleCompanyPhone,
    handleSupportPhone,
    zipInputHandler,
    handleLogoDark,
    handleLogoWhite,
    handleFavicon,
    provinceCodeHandler,
    handleUnlimited,
    setValue,
    handleLimit,
    handleLogoOnClose,
    handleSubmit,
  } = useResellerForm();
  return (
    <form
      className={classes.formContainer}
      onSubmit={handleSubmit(
        async () => await ResellerService.onSubmit(formValue as ResellerType, navigate, adminId),
        (e) => console.log(e)
      )}
    >
      <Stack flexDirection={"column"} gap={3} sx={{ paddingBottom: 2 }}>
        <PaperBlock title={t("RESELLERS.INFOS")} paperElevation={1}>
          <Stack flexDirection={"row"} gap={2}>
            <ResellerMainInfos control={control} formValue={formValue} />
            <ResellerMainContact control={control} formState={formState} formValue={formValue} handleCompanyPhone={handleCompanyPhone} />
          </Stack>
          <BaseAddressForm
            paperElevation={2}
            control={control}
            formState={formState}
            formValue={formValue}
            handleAddressChange={handleAddressChange}
            handleAddressInputValue={handleAddressInputValue}
            zipInputHandler={zipInputHandler}
            provinceCodeHandler={provinceCodeHandler}
          />
          <ResellerSupport control={control} formState={formState} formValue={formValue} handleSupportPhone={handleSupportPhone} />
        </PaperBlock>
        <ResellerInvoiceContact
          control={control}
          formState={formState}
          formValue={formValue}
          handleUnlimited={handleUnlimited}
          handleLimit={handleLimit}
        />
        <PaperBlock title={t("RESELLERS.WHITE_MARK_IDENTITY")} paperElevation={0}>
          <Stack flexDirection={"row"} className="fullWidthContent" gap={2}>
            <ResellerLogos formValue={formValue} setValue={setValue} formState={formState} />
            <ResellerVisibleNames control={control} />
          </Stack>
        </PaperBlock>
        {/* <ResellerCreditInfos control={control} /> */}
      </Stack>
      <FormAction
        isDisabled={isLoading || !formState?.isValid}
        handleCancel={() => navigate("/resellers")}
        isLoading={isLoading}
        isUpdating
      />
      <AddLogoModal setLogo={handleLogoWhite} open={formValue?.showUploadLogoWhite} close={() => handleLogoOnClose("logo")} />
      <AddLogoModal
        setLogo={handleLogoDark}
        open={formValue?.showUploadLogoDark}
        close={() => handleLogoOnClose("logo_dark")}
        darkBackground={true}
      />
      <AddLogoModal setLogo={handleFavicon} open={formValue?.showUploadFavicon} close={() => handleLogoOnClose("favicon")} />
    </form>
  );
};

export default FormReseller;
