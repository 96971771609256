import Icon from "@/components/base/Icon";
import { Button, CircularProgress, Paper } from "@mui/material";
import { useTranslation } from "react-i18next";
import useStyles from "./styles";

interface FormActionProps {
  handleCancel: () => void;
  handleSubmit?: () => void;
  isDisabled?: boolean;
  isLoading: boolean;
  isUpdating?: boolean;
  buttonText?: string;
}

const FormAction = ({
  handleCancel,
  isDisabled,
  isLoading,
  isUpdating,
  buttonText,
  handleSubmit
}: FormActionProps) => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  return (
    <Paper elevation={6} className={classes.wrapper}>
      <Button color="secondary" onClick={handleCancel}>
        {t("FORMS.CANCEL")}
      </Button>
      <Button
        variant={"contained"}
        data-testid="create"
        type="submit"
        data-e2e="submit"
        disabled={isDisabled}
        onClick={handleSubmit}
      >
        {isLoading && (
          <CircularProgress size={16} sx={{ marginRight: "8px" }} />
        )}
        {!isLoading && (
          <Icon
            name={isUpdating ? "save" : "plus"}
            sx={{ marginRight: "8px" }}
          />
        )}
        {buttonText ?? (isUpdating ? t("FORMS.SAVE") : t("FORMS.CREATE"))}
      </Button>
    </Paper>
  );
};

export default FormAction;
