import React, { Fragment } from "react"
import { useTranslation } from "react-i18next"
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material"
import { IconButton, Stack, Tooltip } from "@mui/material"

interface TablePaginationActionsProps {
    count: number
    page: number
    rowsPerPage: number
    onPageChange: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void
}

const TablePaginationActions = (props: TablePaginationActionsProps) => {
    const { count, page, rowsPerPage, onPageChange } = props

    const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page - 1)
    }

    const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page + 1)
    }
    const { t } = useTranslation()

    return (<>
        <Tooltip title={t("PAGINATION.PREVIOUS_PAGE_TOOLTIP")}>
            <span>
                <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                    <KeyboardArrowLeft />
                </IconButton>
            </span>
        </Tooltip>
        <Tooltip title={t("PAGINATION.NEXT_PAGE_TOOLTIP")}>
            <span>
                <IconButton
                    onClick={handleNextButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="next page"
                >
                    <KeyboardArrowRight />
                </IconButton>
            </span>
        </Tooltip>
    </>
    )
}

export default TablePaginationActions
