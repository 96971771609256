import UserSessionStore from "@/store/user-session";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useProfileInfo from "./useProfileInfo";

const UNPROTECTED_ROUTE = ["/login"];
const SHOULD_CLEAR_SESSION_ON_ROUTE = ["/forgot-password", "/reset-password"];
const ADMIN_ONLY_ROUTES = ["/resellers"];

const useRedirectLogic = () => {
  const { isLoggedIn } = UserSessionStore();
  const navigate = useNavigate();
  const { isSuperAdmin } = useProfileInfo();
  const { pathname } = useLocation();

  useEffect(() => {
    // Récupérer l'URL actuelle
    const currentURL = window.location.pathname;

    const isLoginRequired = !UNPROTECTED_ROUTE.includes(
      window.location.pathname
    );
    const shouldClearSession = SHOULD_CLEAR_SESSION_ON_ROUTE.includes(
      window.location.pathname
    );
    const isAdminRoute = ADMIN_ONLY_ROUTES.some((route) =>
      currentURL.startsWith(route)
    );

    const isBasePath = window.location.pathname === "/";

    if (isLoginRequired && !isLoggedIn && !shouldClearSession) {
      navigate("/login");
    } else if (!isSuperAdmin && isAdminRoute) {
      navigate("/accounts");
    } else if (
      (!isLoginRequired && isLoggedIn && !shouldClearSession) ||
      isBasePath
    ) {
      navigate("/accounts");
    }
  }, [isLoggedIn, pathname, isSuperAdmin, navigate]);
};

export default useRedirectLogic;
