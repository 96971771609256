import { UserApi } from "@/api"
import useNotification from "@/hooks/useNotification"
import UserSessionStore from "@/store/user-session"
import { useCallback, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"

const useTopbarEditUser = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const [openModalDelete, setOpenModalDelete] = useState(false)
    const { userId } = useParams()
    const { profile } = UserSessionStore()
    const { notif } = useNotification()
    const [isDeleting, setIsDeleting] = useState(false)

    const handleDelete = useCallback(async () => {
        setIsDeleting(true)
        try {
            const response = await UserApi.deleteUser({ userId, user_uid: profile.uid })
            if (response?.error) {
                throw new Error(response.error)
            }
            notif({
                message: t("FORMS.DELETE_SUCCESS"),
                show: true,
                type: "SUCCESS"
            })
            navigate("/users")
        } catch (error) {
            notif({
                message: t("FORMS.DELETE_FAILED"),
                show: true,
                type: "ERROR"
            })
            setOpenModalDelete(false)
        }
        setIsDeleting(false)
    }, [userId])

    return {
        isDeleting,
        openModalDelete,
        setOpenModalDelete,
        handleDelete
    }
}

export default useTopbarEditUser