import TitleWrapper from "@/components/base/page-container/title-wrapper";
import FormReseller from "@/components/forms/reseller";
import UiStore from "@/store/ui";
import { Stack } from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const ResellersCreate = () => {
  const { updateState } = UiStore();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    updateState({ title: t("RESELLERS.NEW_RESELLER") });
  }, []);
  return (
    <>
      <TitleWrapper title={t("RESELLERS.NEW_RESELLER")} handlePreviousButton={() => navigate("/resellers")} />
      <FormReseller />
    </>
  );
};

export default ResellersCreate;
