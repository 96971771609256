import { SxProps } from "@mui/material";

interface DefaultSx {
  noBottomBorder?: boolean;
  showLabelStar?: boolean;
  error?: boolean;
}

const defaultSx = (props?: DefaultSx): SxProps => ({
  borderRadius: "8px",
  "& fieldset": {
    border: "none",
  },
  "body & .MuiFilledInput-root": {
    backgroundColor: "rgba(172, 182, 229, 0.16)",
    "&::after, &:hover:before, &::before": {
      ...(!props?.noBottomBorder
        ? { borderBottom: "1px solid rgba(91, 125, 216, 0.12)" }
        : { borderBottom: "none" }),
    },
    "&.Mui-error": {
      "&::after, &:hover:before, &::before": {
        borderBottomColor: "error.main",
      },
    },
  },
  "& label, & label.Mui-focused": {
    color: "action.active",
  },
  "& label.Mui-error, & label.Mui-focused.Mui-error": {
    color: "error.main",
  },
  "& .MuiFilledInput-root, & .MuiFilledInput-root::after, & .MuiFilledInput-root.Mui-focused::after":
    {
      borderRadius: "8px",
      overflow: "hidden",
    },
  "& .MuiFormLabel-asterisk": {
    color: props?.error ? "error.main" : "primary.main",
    display: "none",
    ...(props?.showLabelStar && { display: "inline" }),
  },
  "& input": {
    "&:autofill": {
      backgroundClip: "text",
    },
  },
});

const InputLabelSx = ({ error }: { error: boolean }): SxProps => ({
  "&.MuiFormLabel-root": {
      color: "action.active",
  },
  "&.Mui-focused": {
      color: "action.active",
  },
  "&.Mui-error, &.Mui-focused.Mui-error": {
      color: "error.main",
  },
  "& .MuiFormLabel-asterisk": {
      color: error ? "error.main" : "primary.main",
  },
})

export { defaultSx, InputLabelSx };
