import { Autocomplete, AutocompleteProps } from "@mui/material";
import { forwardRef } from "react";

const StyledAutocomplete = forwardRef((props: AutocompleteProps<any, any, any, any>, ref) => {
    return <Autocomplete
        ref={ref}
        {...props}
    />
})

export default StyledAutocomplete