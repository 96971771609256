import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFoot from '@mui/material/TableFooter';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TableVirtuoso, TableComponents } from 'react-virtuoso';
import { SxProps, TablePagination } from '@mui/material';
import TableHeadCellWrapper from '../table-head-cell-wrapper';
import TableHeadSort from '../table-head-sort';
import { useTranslation } from 'react-i18next';
import TablePaginationActions from '../pagination-action';


const VirtuosoTableComponents: TableComponents<any[]> = {
    Scroller: React.forwardRef<HTMLDivElement>((props, ref) => (
        <TableContainer component={Paper} {...props} ref={ref} />
    )),
    Table: (props) => (
        <Table {...props} sx={{
            borderCollapse: 'separate',
            tableLayout: 'fixed',
            minWidth: "1100px",
        }} />
    ),
    TableRow: ({ item: _item, ...props }) => <TableRow {...props} />,
    TableBody: React.forwardRef<HTMLTableSectionElement>((props, ref) => (
        <TableBody {...props} ref={ref} sx={{ position: "relative" }} />
    )),
};


function FixedHeaderContent({ columns, onSort, activeSort }) {
    return (
        <TableRow sx={{
            ".MuiTableCell-root": {
                backgroundColor: "#F4F5FA",
                borderBottom: "none",
                position: "relative",
                overflow: "hidden",
                padding: "0 16px",
                height: "56px",
                "&::after": {
                    content: "''",
                    position: "absolute",
                    height: "1px",
                    width: "100%",
                    backgroundColor: "#5B7DD829",
                    bottom: "0",
                    left: "0",
                },
                "&:first-of-type": {
                    borderRadius: "8px 0px 0px 8px",
                },
                "&:last-child": {
                    borderRadius: "0px 8px 8px 0px",
                },
            },
            ".MuiTypography-root": {
                fontSize: "14px",
                fontWeight: 500,
                lineHeight: "24px",
                letterSpacing: "0.17px",
                textAlign: "left",
            },
        }}
        >
            {columns
                .filter((item) => !item.hidden)
                .map((column) => (
                    <TableHeadCellWrapper
                        key={column.id}
                        style={{ width: column.width }}
                    >
                        {column.sortable ? (
                            <TableHeadSort
                                label={column.label}
                                column={column}
                                onSort={onSort}
                                activeSort={activeSort}
                            />
                        ) : (
                            column.label
                        )}
                    </TableHeadCellWrapper>
                ))}
        </TableRow>
    );
}
const singleLineSx: SxProps = {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
};

function RowContent({ columns, row }) {
    return (
        <React.Fragment>
            {columns
                .filter((item) => !item.hidden)
                .map((column) => {
                    const value = row[column.id];
                    return (
                        <TableCell
                            key={column.id}
                            sx={{
                                ...(!column.multiline && singleLineSx),
                            }}
                        >
                            {column.component
                                ? column.component({ column, row })
                                : value}
                        </TableCell>
                    );
                })}
        </React.Fragment>
    );
}

export default function ReactVirtualizedTable({
    columns,
    rows,
    onSort,
    activeSort,
    pagination,
    onPageChange
}) {
    const { t } = useTranslation()
    return (
        <Paper style={{ height: "calc(100vh - 250px)", width: '100%', padding: 0, border: "none", boxShadow: "none" }}>
            <TableVirtuoso
                style={{
                    padding: 0,
                    borderRadius: 0,
                    boxShadow: "none",
                    position: "relative",
                }}
                data={rows}
                components={VirtuosoTableComponents}
                fixedHeaderContent={() => <FixedHeaderContent columns={columns} onSort={onSort} activeSort={activeSort} />}
                itemContent={(index, row) => <RowContent columns={columns} row={row} />}
                fixedFooterContent={() => pagination ? (
                    <TableRow sx={{ backgroundColor: "white" }}>
                        <TablePagination
                        labelDisplayedRows={({ from, to, count }) => {
                            return (
                                "" +
                                from +
                                "-" +
                                to +
                                " " +
                                t("PAGINATION.PAGING") +
                                " " +
                                count
                            );
                        }}
                        count={pagination?.totalCount}
                        rowsPerPage={pagination?.rowsPerPage}
                        page={pagination?.page}
                        onPageChange={(__, newPage: number) => onPageChange(newPage)}
                        rowsPerPageOptions={[]}
                        ActionsComponent={TablePaginationActions}
                        sx={{ border: "none", height: 58 }}
                    />
                    </TableRow>
                ) : <></> }
            />
        </Paper>
    );
}