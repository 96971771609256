import { CreateCompanyPayload } from "@/api/companies";
import TopBarCompanies from "@/components/topbar/topbar-companies";
import { CompanyService } from "@/services";
import CompanyStore from "@/store/company";
import { Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import BaseAddressForm from "../base/address";
import useStyles from "../base/style";
import FormAction from "../form-action";
import CompanyAdmin from "./admin";
import CompanyLicence from "./licence";
import CompanyMainInfos from "./main-infos";
import CompanyUniqueId from "./unique-id";
import useCompanyForm from "./useCompanyForm";
const FormCompany = () => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const {
    control,
    formValue,
    formState,
    isFormValid,
    adminId,
    zipInputHandler,
    handleAddressChange,
    handleAddressInputValue,
    handleInvitationChange,
    provinceCodeHandler,
    handleBusinessPhone,
    handleMobilePhone,
    handleSubmit,
  } = useCompanyForm();
  const { isLoading } = CompanyStore();
  return (
    <form
      className={classes.formContainer}
      onSubmit={handleSubmit(
        async () => await CompanyService.onSubmit(formValue as CreateCompanyPayload, navigate, adminId),
        (e) => console.log(e)
      )}
    >
      <Stack flexDirection={"column"} gap={3} sx={{ paddingBottom: 2 }}>
        {/* <Suspended /> */}
        {adminId && <TopBarCompanies />}

        <Stack gap={2} flexDirection={"row"}>
          <CompanyMainInfos
            control={control}
            formState={formState}
            formValue={formValue}
            handleBusinessPhone={handleBusinessPhone}
            handleMobilePhone={handleMobilePhone}
          />
          <Stack flexDirection={"column"} gap={adminId ? 2 : 0} sx={{ width: "100%" }}>
            {adminId && <CompanyUniqueId formValue={formValue} />}
            <CompanyLicence control={control} formState={formState} />
          </Stack>
        </Stack>
        <Stack>
          <BaseAddressForm
            control={control}
            formState={formState}
            formValue={formValue}
            handleAddressChange={handleAddressChange}
            handleAddressInputValue={handleAddressInputValue}
            zipInputHandler={zipInputHandler}
            provinceCodeHandler={provinceCodeHandler}
          />
        </Stack>
        <Stack>
          <CompanyAdmin adminId={adminId} control={control} formValue={formValue} handleInvitationChange={handleInvitationChange} />
        </Stack>
      </Stack>
      <FormAction
        isDisabled={!isFormValid || isLoading}
        handleCancel={() => navigate("/accounts")}
        isLoading={isLoading}
        isUpdating
      />
    </form>
  );
};

export default FormCompany;
