import useUsersStore from "@/store/users";
import { isValidEmail } from "@/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { Stack } from "@mui/material";
import { useEffect, useMemo } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { z } from "zod";
import ControllerFormInput from "../../controller/form-input";

type UserInfo = {
  lastname: string;
  firstname: string;
  email: string;
};

const FormUserRole = () => {
  const { t } = useTranslation();
  const {
    update: updateUsersStore,
    previousValue,
    error: errorState,
  } = useUsersStore();
  const { userId } = useParams();

  const defaultValue = useMemo(() => {
    return {
      lastname: "",
      firstname: "",
      email: "",
    };
  }, []);

  const schema = useMemo(() => {
    return z.object({
      lastname: z.string().trim().min(1, t("FORMS.ERROR.FIELD_REQUIRED")),
      firstname: z.string().trim().min(1, t("FORMS.ERROR.FIELD_REQUIRED")),
      email: z
        .string()
        .min(1, t("FORMS.ERROR.FIELD_REQUIRED"))
        .refine((value) => isValidEmail(value), {
          message: t("FORMS.ERROR.INVALID_FORMAT_EMAIL"),
        }),
    });
  }, []);

  const { control, formState, reset, setError } = useForm<UserInfo>({
    defaultValues: defaultValue,
    resolver: zodResolver(schema),
    mode: "onTouched",
  });

  const currentFormValue = useWatch({ control });

  useEffect(() => {
    updateUsersStore({
      isFormMainInfoValid: formState?.isValid,
    });
  }, [formState?.isValid]);

  useEffect(() => {
    updateUsersStore({
      formValue: {
        email: currentFormValue?.email ?? "",
        firstname: currentFormValue?.firstname ?? "",
        lastname: currentFormValue?.lastname ?? "",
      },
    });
  }, [currentFormValue]);

  useEffect(() => {
    if (previousValue?.user_uid === userId) {
      reset(previousValue);
    } else {
      reset({
        email: "",
        firstname: "",
        lastname: "",
      });
    }
  }, [previousValue?.user_uid, userId]);

  useEffect(() => {
    if (errorState?.email) {
      setError("email", { message: errorState.email });
    }
  }, [errorState]);

  return (
    <form>
      <Stack gap={1}>
        <ControllerFormInput
          fieldName={"firstname"}
          control={control}
          label={t("FORMS.FIRSTNAME")}
        />
        <ControllerFormInput
          fieldName={"lastname"}
          control={control}
          label={t("FORMS.LASTNAME")}
        />
        <ControllerFormInput
          fieldName={"email"}
          control={control}
          label={t("FORMS.EMAIL")}
        />
      </Stack>
    </form>
  );
};

export default FormUserRole;
