import { MenuItemType } from "@/types/menu";

const ADMIN_MENU_ITEM_LIST: MenuItemType[] = [
  {
    label: "MENU.ACCOUNTS",
    icon: "profile",
    position: "top",
    href: "/accounts",
  },
  {
    label: "MENU.RESELLERS",
    icon: "building-7",
    position: "top",
    href: "/resellers",
  },
  {
    label: "MENU.USERS",
    icon: "userSvg",
    position: "top",
    href: "/users",
  },
];

export default ADMIN_MENU_ITEM_LIST;
