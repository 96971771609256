import { makeStyles } from "tss-react/mui"

const useStyles = makeStyles()((theme) => ({
    titleWrapper: {
        display: "flex",
        gap: 8,
        alignItems: "center",
        backgroundColor: theme.palette.background.default,
        "& h1": {
            fontSize: 34,
            wordBreak: "break-word",
        },
        "& button": {
            padding: 0,
            minWidth: "fit-content",
            boxShadow: "none",
            margin: "4px 0 auto",
        },
        "& > svg": {
            backgroundColor: "rgba(1, 128, 255, 0.24)",
            padding: 8,
            borderRadius: 16,
            color: theme.palette.primary.main,
        },
        "& svg": {
            fontSize: 16,
        },
    },
}))

export default useStyles
