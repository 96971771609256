import caFlag from "@/assets/icons/flags/ca.png";
import frFlag from "@/assets/icons/flags/fr.png";
import usFlag from "@/assets/icons/flags/us.png";
import { Box } from "@mui/material";
import deburr from "lodash.deburr";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

const imgSx = { display: "flex", "& img": { objectFit: "contain" } };

const useCountryList = () => {
  const { t } = useTranslation();
  const countryList = useMemo(() => {
    const countries = [
      { value: "FR", label: t("COUNTRY.FR"), flag: frFlag },
      { value: "CA", label: t("COUNTRY.CA"), flag: caFlag },
      { value: "US", label: t("COUNTRY.US"), flag: usFlag },
    ];
    return countries
      .sort((a, b) => (deburr(a.label) > deburr(b.label) ? 1 : -1))
      .map((country) => ({
        prefix: (
          <Box sx={imgSx}>
            <img
              src={country.flag}
              alt={country.value}
              width={24}
              height={18}
            />
          </Box>
        ),
        label: country.label,
        value: country.value,
      }));
  }, [t]);

  return {
    countryList,
  };
};

export default useCountryList;
