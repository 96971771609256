import NotificationStore from "@/store/notification";
import { captureException } from "@sentry/react";
import i18next from "i18next";

class ErrorHandlerRequestService {
  asyncHandlerRequestError = async (callback: any, onError?: any) => {
    try {
      const resp = await callback();
      return resp;
    } catch (error) {
      if (error?.response?.status >= 500) {
        this.unHandledRequestError(error);

        return { error: "SYSTEM_ERROR.INVALID_REQUEST" };
      }
      if (error?.response) {
        if (typeof onError === "function") {
          return onError();
        }
        return (
          error.response?.data || { error: "SYSTEM_ERROR.INVALID_REQUEST" }
        );
      }
      return { error: "SYSTEM_ERROR.INVALID_REQUEST" };
    }
  };

  unHandledRequestError = (error) => {
    const { update: showNotification } = NotificationStore.getState();
    showNotification({
      type: "ERROR",
      message: i18next.t("SYSTEM_ERROR.INVALID_REQUEST"),
      show: true,
    });
    captureException(error, { level: "error" })
  };
}

export default new ErrorHandlerRequestService();
