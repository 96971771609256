import { CompanyApi } from "@/api";
import { Companies } from "@/api/companies";
import Icon from "@/components/base/Icon";
import { StatusChipRender } from "@/components/base/status-chip";
import useClipboard from "@/hooks/useClipboard";
import useProfileInfo from "@/hooks/useProfileInfo";
import useAccountsStore from "@/store/accounts";
import UiStore from "@/store/ui";
import UserSessionStore from "@/store/user-session";
import { checkDisplayOfFullDate, keyBy } from "@/utils";
import {
  Button,
  IconButton,
  Link,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link as LinkRouter } from "react-router-dom";
import { Column } from "../partials/custom-table";
import { useDebounce } from "@/hooks/useDebounce";
type ColumnProps = Column & {
  id:
    | "location_name"
    | "feedback_url"
    | "company_name"
    | "location_status"
    | "plan"
    | "vendor"
    | "created"
    | "action";
};

const useTableComptes = () => {
  const { t } = useTranslation();
  const { preferredLanguage } = UiStore();
  const [page, setPage] = useState(0);
  const rowsPerPage = 100;
  const [totalCount, setTotalCount] = useState(0);
  const [activeSort, setActiveSort] = useState<{
    sort_by: string;
    sort_order: "asc" | "desc";
  }>({
    sort_by: "created",
    sort_order: "desc",
  });
  const [rows, setRows] = useState<Companies[]>([]);
  const { profile } = UserSessionStore();
  const { isSuperAdmin } = useProfileInfo();

  const { filter: { search, status }, update: updateAccounts } = useAccountsStore();

  const textValue = useDebounce(search, 300)

  const { clipboardValue, handleClipboardCopy } = useClipboard();

  const fetchCompanies = useCallback(
    (filter = {}) => {
      const query = {
        page_number: 1,
        page_size: rowsPerPage,
        user_uid: profile?.uid,
        ...activeSort,
        ...filter,
      };
      return CompanyApi.list(query);
    },
    [profile?.uid, rowsPerPage, activeSort]
  );

  const refreshRows = useCallback(
    async (filterParams) => {
      const response: any = await fetchCompanies(filterParams);
      const populated =
        response?.data?.map((data) => ({
          ...data,
          created:
            data.created &&
            dayjs(data.created, "YYYY-MM-DD").format("DD MMMM YYYY"),
        })) || [];
      setRows(populated);
      setTotalCount(response?.metadata?._count)
    },
    [fetchCompanies]
  );

  const handleChangePage = useCallback((newPage: number) => {
    setPage(newPage);
    refreshRows({ page_number: newPage + 1, status })
  }, [refreshRows, status])

  const handleSort = useCallback((columnId: string, sorted: boolean) => {
    setActiveSort((prev) => ({
      ...prev,
      sort_by: columnId,
      sort_order: sorted ? "asc" : "desc",
    }));
  }, [setActiveSort])

  const columns: readonly ColumnProps[] = useMemo(() => {
    return [
      {
        id: "location_name",
        label: t("COMMON.BUSINESS"),
        width: "15%",
      },
      {
        id: "feedback_url",
        label: t("COMMON.FEEDBACK_URL"),
        width: "10%",
        component: ({ row }) => {
          if (!row.shortNewGmbReviewUrl) {
            return (
              <Stack gap={1} flexDirection={"row"} alignItems={"center"}>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  width={"60px"}
                ></Typography>
                <Tooltip title={t("COMMON.COPY_MISSING_FEEDBACK_URL_TOOLTIP")}>
                  <IconButton>
                    <Icon name="info" />
                  </IconButton>
                </Tooltip>
              </Stack>
            );
          }
          if (
            clipboardValue &&
            row?.shortNewGmbReviewUrl === JSON.parse(clipboardValue)
          ) {
            return (
              <Stack gap={1} flexDirection={"row"} alignItems={"center"}>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  width={"60px"}
                >
                  {t("COMMON.COPIED")}
                </Typography>
                <IconButton color="success">
                  <Icon name="check" />
                </IconButton>
              </Stack>
            );
          }
          return (
            <Stack gap={1} flexDirection={"row"} alignItems={"center"}>
              <Typography variant="body2" color="text.secondary" width={"60px"}>
                {t("COMMON.COPY")}
              </Typography>
              <IconButton
                onClick={() =>
                  handleClipboardCopy(JSON.stringify(row?.shortNewGmbReviewUrl))
                }
              >
                <Icon name="copy" />
              </IconButton>
            </Stack>
          );
        },
      },
      {
        id: "company_name",
        label: t("COMMON.COMPANY"),
        width: "10%",
        sortable: true,
        component: ({ column, row }) => {
          return (
            <Link
              component={LinkRouter}
              to={`/accounts/edit/${row.company_uid}`}
            >
              {row[column.id]}
            </Link>
          );
        },
      },
      {
        id: "location_status",
        label: t("COMMON.STATUT"),
        width: "17%",
        component: ({ column, row }) => {
          return StatusChipRender(row[column.id]);
        },
      },
      {
        id: "plan",
        label: t("COMMON.LICENSE"),
        width: "9%"
      },
      {
        id: "vendor",
        label: t("COMMON.RESELLER"),
        width: "9%",
        hidden: !isSuperAdmin,
      },
      {
        id: "created",
        label: t("COMMON.CREATED_DATE"),
        width: "13%",
        sortable: true,
        component: ({ column, row }) => {
          return (
            row[column.id] &&
            checkDisplayOfFullDate(row[column.id], preferredLanguage)
          );
        },
      },
      {
        id: "action",
        label: "",
        width: "12%",
        component: ({ row }) => {
          return (
            <Button
              onClick={() =>
                window.open(
                  `${row.front_base_url}?user_uid=${profile?.uid}&company_uid=${
                    row.company_uid
                  }&personae_token=${1}`,
                  "_blank"
                )
              }
            >
              {t("COMMON.CONNEXION")}
            </Button>
          );
        },
      },
    ];
  }, [t, preferredLanguage, isSuperAdmin, clipboardValue]);

  const getBusinessStatus = useCallback(async (user_uid: string) => {
    const { data } = await CompanyApi.getBusinessesStatus({ user_uid })
    updateAccounts({
      count_by_status: keyBy(
        data,
        "status"
      ),
    });
  }, [])

  useEffect(() => {
    if(profile?.uid) {
      getBusinessStatus(profile?.uid)
    }
  }, [profile?.uid])

  useEffect(() => {
    if (profile?.uid) {
      setPage(0)
      refreshRows({ status, search: textValue, page_number: 0 });
    }
  }, [refreshRows, textValue, status,  profile?.uid]);

  return {
    columns,
    page,
    rowsPerPage,
    rows,
    totalCount,
    activeSort,
    handleChangePage,
    handleSort,
  };
};

export default useTableComptes;
