import GoogleAddressAutocomplete from "@/components/autocomplete/google-address-autocomplete";
import BaseInput from "@/components/base/input";
import BaseSelect from "@/components/base/select";
import MAX_INPUT from "@/data/limiter";
import { PROVINCES_CA } from "@/data/provinces";
import { US_STATES_CODE } from "@/data/states";
import useCountryList from "@/hooks/useCountryList";
import { Stack } from "@mui/material";
import deburr from "lodash.deburr";
import { useMemo } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import FormController from "../form-controller";
import PaperBlock from "../paper-block";

interface BaseAddressFormProps {
  control: any;
  formState: any;
  formValue: any;
  paperElevation?: number;
  disabled?: boolean;
  handleAddressChange: (data: any) => void;
  handleAddressInputValue: (address: string) => void;
  zipInputHandler: (e: any) => void;
  provinceCodeHandler: (code: string) => void;
}

const BaseAddressForm = ({
  control,
  formState,
  formValue,
  paperElevation = 1,
  disabled = false,
  handleAddressChange,
  handleAddressInputValue,
  zipInputHandler,
  provinceCodeHandler,
}: BaseAddressFormProps) => {
  const { countryList } = useCountryList();
  const { t } = useTranslation();
  const provinceList = useMemo(() => {
    return PROVINCES_CA.map((code) => ({
      label: t(`PROVINCE.${code}`),
      value: code,
    })).sort((a, b) => (deburr(a.label) > deburr(b.label) ? 1 : -1));
  }, [t]);

  const stateList = useMemo(() => {
    return US_STATES_CODE.map((code) => ({
      label: t(`PROVINCE.${code}`),
      value: code,
    })).sort((a, b) => (deburr(a.label) > deburr(b.label) ? 1 : -1));
  }, [t]);

  return (
    <PaperBlock title={t("COMPANIES.CREATE.ADDRESS")} paperClassNames="inputs-wrapper address" paperElevation={paperElevation}>
      <Stack flexDirection={"column"} gap={1}>
        <Stack flexDirection="row" gap={"8px"} className={"inputs fullWidthContent"}>
          <BaseSelect
            required
            label={t("FORMS.COUNTRY")}
            options={countryList}
            control={control}
            defaultValue={formValue?.country_code}
            formStateError={!!formState?.errors["country_code"]}
            disabled={disabled}
            controlName="country_code"
          />
          <Controller
            name="address"
            control={control}
            render={({ fieldState, field }) => (
              <GoogleAddressAutocomplete
                label={t("FORMS.ADDRESS")}
                onSelect={handleAddressChange}
                required
                countryRestrictions={[formValue?.country_code]}
                value={formValue?.address}
                helperText={fieldState?.error?.message}
                inputValueChange={handleAddressInputValue}
                disabled={disabled}
                error={!!fieldState?.error}
              />
            )}
          />
        </Stack>
        <Stack flexDirection="row" gap={"8px"} className={"inputs fullWidthContent"}>
          <FormController fieldName="city" control={control} fieldLabel={t("FORMS.TOWN")} disabled={disabled} />
          <BaseInput
            label={t("FORMS.ZIP")}
            max={MAX_INPUT.TEXT}
            value={formValue?.zip}
            required
            error={!!formState?.errors["zip"]}
            helperText={formState?.errors["zip"]?.message ?? ""}
            onChange={zipInputHandler}
            data-testid="zip"
            disabled={disabled}
          />
          {formValue?.country_code !== "FR" && (
            <BaseSelect
              defaultValue={formValue?.province_code}
              required
              label={formValue?.country_code === "US" ? t("FORMS.STATE") : t("FORMS.PROVINCE")}
              options={formValue?.country_code === "US" ? stateList : provinceList}
              formStateError={!!formState?.errors["province_code"]}
              helperText={formState?.errors["province_code"]?.message ?? ""}
              className={!!formState?.errors["province_code"] ? "Mui-error" : ""}
              disabled={disabled}
              onItemClicked={provinceCodeHandler}
            />
          )}
        </Stack>
      </Stack>
    </PaperBlock>
  );
};

export default BaseAddressForm;
