import { useCallback, useState } from "react"
import useNotification from "./useNotification"
import { useTranslation } from "react-i18next"

const useClipboard = () => {

    const [clipboardValue, setClipboardValue] = useState<string>(null)
    const {notif} = useNotification()
    const { t } = useTranslation()

    const handleClipboardCopy = useCallback(
        (value: string) => {
            try {
                setClipboardValue(value)
                navigator.clipboard.writeText(JSON.parse(value))
                setTimeout(() => {
                    setClipboardValue(null)
                }, 3000)
            } catch(error) {
                notif({
                    type: "ERROR",
                    show: true,
                    message: t("COMMON.COPY_FAILED")
                })
            }
        },[t]
    )

    return {
        handleClipboardCopy,
        clipboardValue
    }
}

export default useClipboard