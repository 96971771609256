import SearchInputFilter from "@/components/search-input-filter";
import useProfileInfo from "@/hooks/useProfileInfo";
import useAccountsStore from "@/store/accounts";
import { Add } from "@mui/icons-material";
import { Button, Paper, Stack } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export type FilterCompanyValue = {
  status: string[];
  text: string;
};

const TopbarComptes = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isSuperAdmin } = useProfileInfo();
  const {
    update: updateAccounts,
    count_by_status,
  } = useAccountsStore();
  const [withSearch, setWithSearch] = useState({
    inputLabel: "COMMON.SEARCH",
    popoverTitle: "COMMON.STATUT",
    filterText: "BUSINESSES.FILTER_TOOLTIP_NO_SELECT",
    checkboxList: [
      {
        label: "STATUS.EMPTY_COMPANY",
        name: "empty_company",
        checked: false,
        count: 0,
      },
      {
        label: "STATUS.CONNECTED",
        name: "connected",
        checked: false,
        count: 0,
      },
      {
        label: "STATUS.DUPLICATED",
        name: "duplicated",
        checked: false,
        count: 0,
      },
      {
        label: "STATUS.DISCONNECTED",
        name: "disconnected",
        checked: false,
        count: 0,
      },
      {
        label: "STATUS.NOT_DETECTED",
        name: "not_detected",
        checked: false,
        count: 0,
      },
      {
        label: "STATUS.NOT_VERIFIED",
        name: "not_verified",
        checked: false,
        count: 0,
      },
      {
        label: "STATUS.SUSPENDED",
        name: "is_suspended",
        checked: false,
        count: 0,
      },
    ],
    textSearch: "",
  });

  const checkboxList = useMemo(() => {
    return withSearch?.checkboxList?.map((current) => ({
      ...current,
      count:
        count_by_status?.[current.name?.toUpperCase()]?.count ||
        current.count ||
        0,
    }));
  }, [withSearch?.checkboxList, count_by_status]);

  useEffect(() => {
    updateAccounts({
      filter: {
        status: withSearch?.checkboxList
          ?.filter((check) => check.checked)
          ?.map((check) => check.name?.toUpperCase())
          .join(","),
        search: withSearch?.textSearch ?? "",
      },
    });
  }, [withSearch?.checkboxList, withSearch?.textSearch]);

  return (
    <Paper
      data-testid="topbar-container"
      sx={{
        padding: "8px",
        "@media (min-width: 1024px)": {
          padding: "8px 16px",
        },
      }}
    >
      <Stack
        flexDirection={{
          md: "row",
          sm: "column",
        }}
        justifyContent={"space-between"}
        alignItems={"center"}
        gap={{
          sm: "8px",
          xs: "8px",
        }}
      >
        <SearchInputFilter
          withSearch={{
            ...withSearch,
            checkboxList,
          }}
          setWithSearch={setWithSearch}
        />
        {!isSuperAdmin && (
          <Button
            startIcon={<Add />}
            variant="contained"
            data-testid="button-action"
            sx={{
              width: "100%",
              "@media (min-width: 1024px)": {
                width: "fit-content",
              },
            }}
            onClick={() => navigate("/accounts/create")}
          >
            {t("COMPANIES.NEW_COMPANY")}
          </Button>
        )}
      </Stack>
    </Paper>
  );
};

export default TopbarComptes;
