import SelectLang from "@/components/select/select-lang";
import { Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import FormController from "../../base/form-controller";
import PaperBlock from "../../base/paper-block";

const ResellerMainInfos = ({ control, formValue }) => {
  const { t } = useTranslation();
  return (
    <PaperBlock title={t("RESELLERS.MAIN_INFOS")} titleVariant="h3">
      <Stack flexDirection={"column"} gap={1}>
        <FormController
          fieldLabel={t("FORMS.NAME")}
          fieldName="name"
          control={control}
        />
        <FormController
          fieldLabel={t("FORMS.URL_CGV")}
          fieldName="cgv_url"
          control={control}
          required={false}
        />
        <SelectLang
          defaultValue={formValue?.lang}
          options={[
            {
              label: t("FORMS.LANGS.FR"),
              value: "fr-CA",
            },
            {
              label: t("FORMS.LANGS.EN"),
              value: "en",
            },
          ]}
          control={control}
          controlName="lang"
          label={t("FORMS.LANG")}
          required
        />
      </Stack>
    </PaperBlock>
  );
};

export default ResellerMainInfos;
