import { CompanyApi } from "@/api";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const useMainInfos = () => {
  const { t } = useTranslation();
  const [business, setBusiness] = useState<
    { value?: string; label?: string }[]
  >([]);

  const fetch = useCallback(async () => {
    const resp = await CompanyApi.getBusiness();
    if (resp && resp?.length) {
      setBusiness(
        resp
          ?.sort((a, b) => (a.name > b.name ? 1 : -1))
          ?.map((item) => {
            return {
              value: item?.uid,
              label: t(`ACTIVITY.${item?.name}`),
            };
          })
      );
    }
  }, [t]);

  useEffect(() => {
    fetch();
  }, []);

  return {
    business,
  };
};

export default useMainInfos;
