import BaseInput from "@/components/base/input"
import { Control, Controller } from "react-hook-form"

type ControllerFormInputProps<T> = {
    fieldName: string
    label: string
    control: Control<T>
}

const ControllerFormInput = ({
    fieldName,
    label,
    control
}: ControllerFormInputProps<any>) => {
    return <Controller
        render={({ field, fieldState }) => (
            <BaseInput
                label={label}
                required
                error={!!fieldState?.error}
                helperText={fieldState?.error?.message}
                {...field}
            />
        )}
        control={control}
        name={fieldName}
    />
}

export default ControllerFormInput