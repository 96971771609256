const ENV = import.meta.env

const env = {
  APP_VERSION: ENV.VITE_VERSION,
  ENV: ENV.VITE_ENV,
  MUIX_API_KEY: ENV.VITE_MUIX_API_KEY,
  GW_BASE_URL: ENV.VITE_GW_BASE_URL,
  APPBACK_API_PATH: `${ENV.VITE_GW_BASE_URL}/${ENV.VITE_APPBACK_API_PATH}`,
  DISPATCHR_API_PATH: `${ENV.VITE_GW_BASE_URL}/${ENV.VITE_DISPATCHR_API_PATH}`,
  GOOGLE_MAPS_API_KEY: ENV.VITE_GOOGLE_MAPS_API_KEY,
  COOKIE_NAME: ENV.VITE_COOKIE_NAME ?? "DILYPSE_BO",

  IS_STAGING: ENV.ENV === "staging",
  IS_PROD: ENV.ENV === "prod",
  IS_DEV: ENV.ENV === "dev",

  SENTRY_DSN: ENV.VITE_SENTRY_DSN,
  SENTRY_TRACE_SAMPLE_RATE: Number(ENV.VITE_SENTRY_TRACE_SAMPLE_RATE),
  SENTRY_REPLAYS_SESSION_SAMPLE_RATE: Number(ENV.VITE_SENTRY_REPLAYS_SESSION_SAMPLE_RATE),
  SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE: Number(ENV.SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE),
};

export default env