import { create } from "zustand"

type IAccountsStore = {
    filter: {
        status: string
        search: string
    }
    count_by_status: {
        [status: string]: {
            status: string,
            count: number
        }
    }
}

export type IAccountsSetter = {
    update: (update: Partial<IAccountsStore>) => void
    flush: () => void
}

const initalState: IAccountsStore = {
    filter: {
        status: "",
        search: ""
    },
    count_by_status: {}
}

const useAccountsStore = create<IAccountsStore & IAccountsSetter>((set) => ({
    ...initalState,
    update: (update) => set(() => ({ ...update })),
    flush: () => set(initalState)
}))

export default useAccountsStore
