import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const useScrollTop = (selector: string) => {
  const { pathname } = useLocation();
  useEffect(() => {
    const wrapper = document.querySelector(selector);
    if (wrapper) {
      wrapper.scrollTo(0, 0);
    }
  }, [pathname, selector]);
};

export default useScrollTop;
