import Icon from "@/components/base/Icon";
import CustomTooltip from "@/components/base/Tooltip";
import { withSxProp } from "@/utils";
import {
  Box,
  Button,
  IconButton,
  Modal,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { modalStyle } from "../base/styles";
import { labelErrorSx, logoSx } from "./styles";
import useLogicLogo from "./useAddLogo";

const AddLogoModal = ({ open, close, setLogo, darkBackground = false }) => {
  const { t } = useTranslation();
  const {
    inputChangeHandler,
    handleImage,
    imageFile,
    error,
    closeModal,
    isThereStaticLogo,
    onAdd,
    labelRefHandler,
    label,
  } = useLogicLogo({
    setLogo,
    close,
  });

  return (
    <Modal
      open={open}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Paper elevation={6} sx={withSxProp(modalStyle, logoSx)}>
        <Box className="title-wrapper">
          <Typography
            id="modal-modal-title"
            data-testid="title"
            variant="h2"
            data-e2e="add-logo-title"
          >
            {t("MODAL.ADD_FILE")}
          </Typography>
          <CustomTooltip data-testid="title-close" title={t("BUTTON.CLOSE")}>
            <IconButton onClick={closeModal} className="btn-x">
              <Icon name="x" />
            </IconButton>
          </CustomTooltip>
        </Box>
        <Box sx={{ width: "100%" }}>
          <Box
            component={"label"}
            ref={labelRefHandler}
            className="drop-zone"
            onDrop={(e) => {
              e.preventDefault();
              handleImage(e.dataTransfer.files[0]);
            }}
            onDragOver={(e) => {
              e.preventDefault();
            }}
            sx={labelErrorSx({
              error,
              height: label?.offsetWidth,
              hidden: !!isThereStaticLogo,
            })}
          >
            <Icon name="image-plus" className="image-plus" />
            <Box>
              <Typography
                data-testid="title-choose"
                component={"span"}
                className="btn-txt"
              >
                {t("MODAL.CHOOSE_FILE")}
              </Typography>{" "}
              <Typography
                data-testid="title-deposite"
                component="span"
                sx={{
                  display: "none",
                  "@media (min-width: 768px)": { display: "initial" },
                }}
              >
                {t("MODAL.DEPOSIT_FILE")}
              </Typography>
            </Box>
            <Stack sx={{ alignItems: "center" }} className="prerequisites">
              <Typography
                dangerouslySetInnerHTML={{
                  __html: t("MODAL.MAX_SIZE", {
                    maxSize: "<strong>10 Mo</strong>",
                    interpolation: { escapeValue: false },
                  }),
                }}
                component="span"
                className="image-type"
                variant="caption"
              />
              <Typography
                component="span"
                className="image-type"
                variant="caption"
              >
                {t("MODAL.ACCEPTED_EXTENSION")}: <strong>JPEG, JPG, PNG</strong>
              </Typography>
              {/* <Typography
                component="span"
                className="image-type"
                variant="caption"
              >
                {t("MODAL.FORMAT")}: <strong>{t("MODAL.SQUARED")}</strong>
              </Typography> */}
              <Typography
                component="span"
                className="image-type"
                variant="caption"
              >
                {t("MODAL.MIN_FORMAT")}: <strong>250x250px</strong>
              </Typography>
            </Stack>
            <input
              type="file"
              hidden
              accept=".png, .jpg, .jpeg"
              onChange={inputChangeHandler}
            />
          </Box>
          {isThereStaticLogo && (
            <Box
              onClick={() => label?.click()}
              sx={{
                maxWidth: 346,
                height: label?.offsetWidth,
                color: "common.white",
                position: "relative",
                borderRadius: 1,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                ...(darkBackground ? { backgroundColor: "#00041F" } : {}),
                "@media (min-width: 768px)": { height: 225 },
                "&::before": {
                  content: '""',
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                  top: 0,
                  left: 0,
                  backgroundColor: "rgba(53, 56, 75, 0.42)",
                  borderRadius: 1,
                  opacity: 0,
                  transition: "opacity 600ms ease-in-out",
                  cursor: "pointer",
                },
                "&:hover": {
                  "&::before": {
                    opacity: 1,
                  },
                  "& svg": {
                    opacity: 1,
                  },
                },

                "& img": {
                  ...(darkBackground ? { backgroundColor: "#00041F" } : {}),
                },
              }}
            >
              <Box
                component={"img"}
                src={URL.createObjectURL(imageFile)}
                alt="Logo"
                sx={{ width: "100%", maxHeight: "100%", borderRadius: 1 }}
              />
              <Icon
                name="image-plus"
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  opacity: 0,
                  transition: "opacity 600ms ease-in-out",
                }}
              />
            </Box>
          )}
          {error && (
            <Typography component="span" className="error">
              {t("MODAL.ERROR_FILE")}
            </Typography>
          )}
        </Box>
        <Box className="action-wrapper">
          <Button onClick={closeModal}>{t("FORMS.CANCEL")}</Button>
          <Button
            variant={"contained"}
            onClick={onAdd}
            sx={{ display: "flex", gap: 1, alignItems: "center" }}
            disabled={!isThereStaticLogo}
          >
            <Icon name="file-plus" /> {t("COMMON.ADD")}
          </Button>
        </Box>
      </Paper>
    </Modal>
  );
};

export default AddLogoModal;
