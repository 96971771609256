import { Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import FormController from "../../base/form-controller";
import PaperBlock from "../../base/paper-block";

const ResellerVisibleNames = ({ control }) => {
  const { t } = useTranslation();
  return (
    <PaperBlock title={t("RESELLERS.VISIBLE_NAMES")} titleVariant="h3">
      <Stack gap={1} flexDirection={"column"}>
        <FormController
          control={control}
          fieldLabel={t("FORMS.PRODUCT_URL")}
          fieldName="url_white_label"
        />
        <FormController
          control={control}
          fieldLabel={t("FORMS.EMAIL_EXPEDITOR")}
          fieldName="email_spotconnect"
          required={false}
        />
        <FormController
          control={control}
          fieldLabel={t("FORMS.SMS_EXPEDITOR")}
          fieldName="sms_sender"
          required={false}
        />
      </Stack>
    </PaperBlock>
  );
};

export default ResellerVisibleNames;
