import { create } from "zustand"

type IUsersStore = {
    formValue: Partial<{
        firstname: string
        lastname: string
        email: string
        role: string
    }>
    isFormRoleValid: boolean
    isFormMainInfoValid: boolean
    previousValue: Partial<{
        firstname: string
        lastname: string
        email: string
        role: string
        user_uid: string
    }>
    error: {
        [field: string]: string
    }
}

export type IUsersSetter = {
    setError: (field: string, errorMessage: string) => void
    update: (update: Partial<IUsersStore>) => void
    flush: () => void
}

const initalState: IUsersStore = {
    formValue: {
        firstname: "",
        lastname: "",
        email: "",
        role: ""
    },
    isFormRoleValid: false,
    isFormMainInfoValid: false,
    previousValue: null,
    error: {}
}

const useUsersStore = create<IUsersStore & IUsersSetter>((set) => ({
    ...initalState,
    setError: (field, errorMessage) => set((previousState) => ({
        error: {
            ...previousState.error,
            [field]: errorMessage
        }
    })),
    update: (update) => set((previousState) => ({
        ...update,
        formValue: {
            ...previousState.formValue,
            ...update.formValue
        }
    })),
    flush: () => set(initalState)
}))

export default useUsersStore
