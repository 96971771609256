import BaseInput from "@/components/base/input";
import { useTranslation } from "react-i18next";
import PaperBlock from "../../base/paper-block";

const CompanyUniqueId = ({ formValue }) => {
  const { t } = useTranslation();
  return (
    <PaperBlock title={t("COMPANIES.EDIT.IDENTITY")} paperElevation={0}>
      <BaseInput value={formValue?.network_uid} label={t("FORMS.ID_NETWORK")} disabled />
      <BaseInput value={formValue?.uid} label={t("FORMS.ID_COMPANY")} disabled />
    </PaperBlock>
  );
};

export default CompanyUniqueId;
