import { ResellerType } from "@/api/reseller";
import env from "@/config/env";
import { CookiesService } from "@/services";
import Cookies from "js-cookie";
import { create } from "zustand";

export interface IResellerStore {
  resellerLogoDark: string;
  resellerLogo: string;
  resellerName: string;
  resellerFavicon: string;
  resellerUid: string;
  resellerFetched: boolean;
  isLoading: boolean;
  conflictedFields: string[];
  resellerInfos: ResellerType | null;
}

export interface IResellerSetter {
  updateState?: (update: Partial<IResellerStore>) => void;
}

const {
  resellerLogo,
  resellerLogoDark,
  resellerName,
  resellerFavicon,
  resellerUid
} = JSON.parse(Cookies.get(env.COOKIE_NAME) ?? "{}")

const initalState = {
  resellerLogo,
  resellerLogoDark,
  resellerName,
  resellerFavicon,
  resellerUid,
  resellerFetched: false,
  isLoading: false,
  conflictedFields: [],
  resellerInfos: null,
};

const ResellerStore = create<IResellerStore & IResellerSetter>((set) => ({
  ...initalState,
  updateState: (update) => set(() => ({ ...update })),
}));

export default ResellerStore;
