import { MenuItemType } from "@/types/menu";

const MENU_ITEM_LIST: MenuItemType[] = [
  {
    label: "MENU.ACCOUNTS",
    icon: "profile",
    position: "top",
    href: "/accounts",
  },
  {
    label: "MENU.USERS",
    icon: "userSvg",
    position: "top",
    href: "/users",
  },
];

export default MENU_ITEM_LIST;
