import HeaderTitle from "@/components/base/header-title";
import TableComptes from "@/components/table/table-comptes";
import TopbarComptes from "@/components/topbar/topbar-accounts";
import useAccountsStore from "@/store/accounts";
import UiStore from "@/store/ui";
import { Paper } from "@mui/material";
import { memo, useEffect } from "react";
import { useTranslation } from "react-i18next";

const MemoizedTable = memo(TableComptes)

const ComptesPage = () => {
  const { t } = useTranslation();
  const { updateState } = UiStore();
  const { flush: flushComptes } = useAccountsStore();

  useEffect(() => {
    updateState({ title: t("MENU.ACCOUNTS") });
    return () => {
      flushComptes()
    }
  }, []);
  return (
    <>
      <HeaderTitle icon="profile" title={t("MENU.ACCOUNTS")} />
      <TopbarComptes />
      <Paper data-testid="table-comptes">
        <MemoizedTable />
      </Paper>
    </>
  );
};

export default ComptesPage;
