import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  formContainer: {
    height: "100%",
    "& .error": {
      textAlign: "center",
    },
    "& h2": {
      fontSize: 24,
      color: "black",
      fontWeight: 500,
    },
    "& h3": {
      fontSize: 20,
    },
    "& .MuiBox-root": {
      display: "flex",
      flexDirection: "column",
      gap: 8,
    },
    "& .title": {
      display: "flex",
      gap: 8,
      alignItems: "center",
      "& .title-icon": {
        color: theme.palette.action.active,
      },
    },
    "& .MuiInputLabel-asterisk": {
      color: theme.palette.action.active,
      "&.Mui-error": {
        color: theme.palette.error.main,
      },
    },
    "& .helper-Error": {
      fontWeight: 400,
      fontSize: "0.75rem",
      lineHeight: 1.66,
      textTransform: "initial",
      textAlign: "left",
      marginTop: 3,
      marginRight: 14,
      marginBottom: 0,
      marginLeft: 0,
      color: theme.palette.error.main,
    },
    "& .fullWidthContent": {
      flexGrow: "wrap",
      width: "100%",
      height: "fit-content",
      "& > *": {
        width: "100%",
      },
      "& .MuiAutocomplete-root .MuiFormControl-root": {
        width: "100%",
      },
    },
    "& .logo-wrapper": {
      display: "flex",
      flexDirection: "column",
      gap: 8,
      // height: 131,
      "& button": {
        height: "100%",
      },
      "@media (min-width: 768px)": {
        flexDirection: "row",
        gap: 16,
      },
      "& .image-frame": {
        minWidth: 124,
        width: 124,
        minHeight: 124,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(1, 128, 255, 0.12)",
        borderRadius: 8,
        gap: 8,
        padding: 8,
        "& span": {
          textAlign: "center",
        },
        "&.without-logo": {
          border: `2px solid ${theme.palette.error.main}`,
          span: {
            color: theme.palette.error.main,
          },
          svg: {
            path: {
              stroke: theme.palette.error.main,
            },
          },
        },
        "&.image-preview": {
          backgroundColor: "transparent",
          boxSizing: "border-box",
          gap: "8px",
          padding: 0,
          minWidth: 124,
          width: 124,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          "& button": {
            padding: 0,
          },
          "& > .MuiBox-root": {
            width: "100%",
            height: "100%",
            borderRadius: 8,
            position: "relative",
            "&::before": {
              content: '""',
              width: "100%",
              height: "100%",
              position: "absolute",
              top: 0,
              left: 0,
              backgroundColor: "rgba(53, 56, 75, 0.42)",
              borderRadius: 8,
              opacity: 0,
              transition: "opacity 600ms ease-in-out",
            },
            "& img": {
              width: "100%",
              height: "100%",
              objectFit: "contain",
              borderRadius: 8,
            },
            "& svg": {
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
              color: theme.palette.common.white,
              opacity: 0,
              transition: "opacity 600ms ease-in-out",
            },
            "&:hover": {
              "&::before": {
                opacity: 1,
              },
              "& svg": {
                opacity: 1,
              },
            },
          },
        },
      },
    },
  },
}));

export default useStyles;
