import { ApiService } from "@/api";
import useRedirectLogic from "@/hooks/useRedirectLogic";
import useWindowFocus from "@/hooks/useWindowFocus";
import Layout from "@/layout";
import { CookiesService } from "@/services";
import UserSessionStore from "@/store/user-session";
import { useCallback } from "react";
import { Outlet } from "react-router-dom";

const PrivateOutlet = () => {
  const { isLoggedIn } = UserSessionStore();
  useRedirectLogic();
  useWindowFocus({
      onFocus: useCallback(() => {
          const { sessionChanged } = ApiService.getVerifySession()
          const user_uid = CookiesService.get("user_uid")
          if (sessionChanged || !user_uid) {
              window.location.reload()
          }
      }, []),
  })

  return (
    <>
      {isLoggedIn ? (
        <Layout>
          <Outlet />
        </Layout>
      ) : (
        <></>
      )}
    </>
  );
};

export default PrivateOutlet;
