import CustomTable from "../partials/custom-table";
import useTableUsers from "./useTableUsers";

const TableUsers = () => {
    const {
        columns,
        page,
        totalCount,
        rowsPerPage,
        rows,
        handleChangePage,
    } = useTableUsers()
    return <CustomTable
        showHeader={false}
        columns={columns}
        rows={rows}
        pagination={{
            page,
            rowsPerPage,
            totalCount
        }}
        onPageChange={handleChangePage}
    />
}

export default TableUsers