import NotificationStore from "@/store/notification";
import { Box } from "@mui/material";
import classNames from "classnames";
import { useMemo } from "react";
import SnackbarError from "./snackbar-error";
import SnackbarNewVersion from "./snackbar-new-version";
import SnackbarSuccess from "./snackbar-success";
import useStyles from "./style";

const Notification = () => {
  const { classes } = useStyles();
  const { type, show } = NotificationStore();
  const FULL_PAGE = ["/login", "/forgot-password", "/reset-password"];
  const isFullPage = useMemo(() => {
    return FULL_PAGE.includes(window.location.pathname);
  }, [window.location.pathname]);
  return (
    <Box
      className={classNames(
        classes.snackbarsWrapper,
        show && "shown",
        isFullPage && "fullPage"
      )}
    >
      {type === "ERROR" && <SnackbarError />}
      {type === "SUCCESS" && <SnackbarSuccess />}
      {type === "UPDATE" && <SnackbarNewVersion />}
    </Box>
  );
};

export default Notification;
