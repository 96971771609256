import { ResellerApi } from "@/api";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const useInvoiceContact = () => {
  const { t } = useTranslation();
  const [currencies, setCurrencies] = useState<
    { label: string; value: string }[]
  >([]);
  const [paymentTypes, setPaymentTypes] = useState<
    { label: string; value: string }[]
  >([]);

  useEffect(() => {
    const getResellerInvoiceSettings = async () => {
      const resp: any = await ResellerApi.getResellerInvoiceSettings();
      if (!resp?.error) {
        setCurrencies(
          resp?.currency?.map((item: string) => {
            return {
              label: item?.toUpperCase(),
              value: item,
            };
          })
        );
        setPaymentTypes(
          resp?.payment_type?.map((item: { key: string; id: number }) => {
            return {
              label: t(`RESELLERS.PAYMENT.${item?.key?.toUpperCase()}`),
              value: item?.id,
            };
          })
        );
      }
    };
    getResellerInvoiceSettings();

    return () => {
      setCurrencies([]);
      setPaymentTypes([]);
    };
  }, []);

  return {
    currencies,
    paymentTypes,
  };
};

export default useInvoiceContact;
