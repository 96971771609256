import TitleWrapper from "@/components/base/page-container/title-wrapper";
import FormAction from "@/components/forms/form-action";
import FormUserMain from "@/components/forms/users/form-user-main";
import FormRole from "@/components/forms/users/form-user-role";
import useUsersForm from "@/components/forms/users/useUsersForm";
import PaperBloc from "@/components/paper-bloc";
import useUiPageSession from "@/hooks/useUiPageSession";
import UiStore from "@/store/ui";
import { Stack } from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const CreateUserPage = () => {
  useUiPageSession({ title: "Users", page: "user-create" });
  const { t } = useTranslation();
  const { previousPage } = UiStore();
  const { isFormValid, isSubmitting, resetUserFormStore, handlePrevious, handleSubmit, handleCancel } = useUsersForm();

  useEffect(() => {
    if (!previousPage || previousPage === "user-edit") {
      resetUserFormStore();
    }
  }, [previousPage]);

  return (
    <>
      <TitleWrapper title={"USERS.ADD_USER"} handlePreviousButton={handlePrevious} />
      <form>
        <Stack gap={3} flexDirection={"row"}>
          <PaperBloc title="USERS.MAIN_INFORMATION" sx={{ width: "100%" }}>
            <FormUserMain />
          </PaperBloc>
          <PaperBloc title="USERS.ROLE" sx={{ width: "100%" }}>
            <FormRole />
          </PaperBloc>
        </Stack>
        <FormAction
          handleCancel={handleCancel}
          isLoading={isSubmitting}
          buttonText={t("FORMS.SAVE")}
          isUpdating
          isDisabled={isSubmitting || !isFormValid}
          handleSubmit={handleSubmit}
        />
      </form>
    </>
  );
};

export default CreateUserPage;
