import BaseInput from "@/components/base/input";
import { Control, Controller } from "react-hook-form";

interface FormControllerProps {
  control: Control;
  fieldName: string;
  fieldLabel: string;
  required?: boolean;
  disabled?: boolean;
  type?: string;
}

const FormController = ({
  control,
  fieldName,
  fieldLabel,
  required = true,
  disabled = false,
  type = "text",
}: FormControllerProps) => {
  return (
    <Controller
      render={({ field, fieldState }) => (
        <BaseInput
          label={fieldLabel}
          required={required}
          error={!!fieldState?.error}
          data-testid={fieldName}
          helperText={fieldState?.error?.message ?? ""}
          disabled={disabled}
          type={type}
          {...field}
        />
      )}
      control={control}
      name={fieldName}
    />
  );
};

export default FormController;
