import BaseInputPhone from "@/components/base/input-phone";
import { PHONE_COUNTRIES } from "@/data/phoneCountries";
import UiStore from "@/store/ui";
import { Stack } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import FormController from "../../base/form-controller";
import PaperBlock from "../../base/paper-block";

const ResellerSupport = ({
  control,
  formState,
  formValue,
  handleSupportPhone,
}) => {
  const { t } = useTranslation();
  const { preferredLanguage } = UiStore();

  const langOfInputPhone = useMemo(() => {
    return preferredLanguage?.toLowerCase() || "fr-ca";
  }, [preferredLanguage]);
  return (
    <PaperBlock title={t("RESELLERS.SUPPORT")} titleVariant="h3">
      <Stack flexDirection={"row"} gap={1} className="fullWidthContent">
        <FormController
          control={control}
          fieldLabel={t("FORMS.SUPPORT_URL")}
          fieldName="url_support"
          required={false}
        />
        <FormController
          control={control}
          fieldLabel={t("FORMS.SUPPORT_EMAIL")}
          fieldName="email_support"
          required={false}
        />

        <BaseInputPhone
          label={t("FORMS.SUPPORT_PHONE")}
          onlyCountries={PHONE_COUNTRIES}
          error={!!formState?.errors["phone_support"]}
          lang={langOfInputPhone}
          defaultCountry="CA"
          errorLabel={formState?.errors["phone_support"]?.message ?? ""}
          value={formValue?.phone_support}
          name="phone_support"
          onChange={handleSupportPhone}
        />
      </Stack>
    </PaperBlock>
  );
};

export default ResellerSupport;
