import CrashAppComponent from "@/components/forms/base/errors/crash-app";
import ComptesPage from "@/pages/accounts";
import CompaniesCreate from "@/pages/companies/create";
import CompaniesEdit from "@/pages/companies/edit";
import Error404 from "@/pages/errors/404";
import LoginPage from "@/pages/login";
import ForgotPasswordPage from "@/pages/password/forgot-password";
import ResetPasswordPage from "@/pages/password/reset-password";
import { default as ResellersPage } from "@/pages/resellers";
import ResellersCreate from "@/pages/resellers/create";
import ResellersEdit from "@/pages/resellers/edit";
import UsersPage from "@/pages/users";
import CreateUserPage from "@/pages/users/create";
import EditUserPage from "@/pages/users/edit";
import { captureException } from "@sentry/react";
import { useEffect } from "react";
import { RouteObject, useRouteError } from "react-router-dom";
import GlobalOutlet from "./global-outlet";
import PrivateOutlet from "./private-outlet";
import PublicOutlet from "./public-outlet";

function SentryRouteErrorFallback() {
  const routeError = useRouteError();

  useEffect(() => {
    captureException(routeError, { level: "fatal" });
  }, [routeError]);

  return <CrashAppComponent />;
}

const RoutesList: RouteObject[] = [
  {
    path: "/",
    element: <GlobalOutlet />,
    errorElement: <SentryRouteErrorFallback />,
    children: [
      {
        path: "*",
        element: <Error404 />,
      },
      {
        element: <PublicOutlet />,
        path: "/",
        children: [
          {
            path: "login",
            element: <LoginPage />,
          },
          {
            path: "forgot-password",
            element: <ForgotPasswordPage handleSubmit={null} />,
          },
          {
            path: "reset-password",
            element: <ResetPasswordPage />,
          },
        ],
      },

      {
        element: <PrivateOutlet />,
        path: "/",
        children: [
          {
            element: <ComptesPage />,
          },
          {
            path: "accounts",
            element: <ComptesPage />,
          },
          {
            path: "accounts",
            children: [
              {
                path: "create",
                element: <CompaniesCreate />,
              },
              {
                path: "edit/:company_uid",
                element: <CompaniesEdit />,
              },
            ],
          },
          {
            path: "resellers",
            element: <ResellersPage />,
          },
          {
            path: "resellers/create",
            element: <ResellersCreate />,
          },
          {
            path: "resellers/edit/:resellerId",
            element: <ResellersEdit />,
          },
          {
            path: "users",
            children: [
              {
                index: true,
                element: <UsersPage />,
              },
              {
                path: "create",
                element: <CreateUserPage />,
              },
              {
                path: "edit/:userId",
                element: <EditUserPage />,
              },
            ],
          },
        ],
      },
    ],
  },
];

export default RoutesList;
