import UiStore from "@/store/ui"
import { useCallback, useEffect, useMemo, useState } from "react"
import { debounce } from "@mui/material"
import useLoadGoogleMap from "@/hooks/useLoadGoogleMap"

const autocompleteService = { current: null }
const placesService = { current: null }

type UseGoogleAddressProps = {
    countryRestrictions: string[]
}

type StructuredFormatting = {
    main_text: string
    secondary_text: string
    main_text_matched_substrings?: readonly MainTextMatchedSubstrings[]
}

type MainTextMatchedSubstrings = {
    offset: number
    length: number
}

type PlaceRequest = {
    input: string
    sessionToken?: string
    fields?: string[]
    language?: string // https://developers.google.com/maps/faq#languagesupport
    componentRestrictions?: any // https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#ComponentRestrictions
    locationRestriction?: any // https://developers.google.com/maps/documentation/javascript/reference/places-service#LocationRestriction
    types?: string[] // https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#QueryAutocompletionRequest
}

export type PlaceType = {
    description: string
    structured_formatting: StructuredFormatting
}


type PlaceCallback = (results?: readonly PlaceType[]) => void


const useGoogleAddress = ({ countryRestrictions = ["CA"] }: Partial<UseGoogleAddressProps>) => {

    useLoadGoogleMap()

    const [sessionToken, setSessionToken] = useState()
    const { preferredLanguage } = UiStore()

    const fetch = useMemo(() => {
        const requestGooglePlaceApi = (request: PlaceRequest, callback: PlaceCallback) => {
            return (autocompleteService.current)?.getPlacePredictions(request, callback)
        }
        return debounce(requestGooglePlaceApi, 400)
    }, [])

    const requestOpts = useMemo(() => {
        const countries = countryRestrictions?.filter(country => !!country)
        return {
            language: preferredLanguage,
            types: ["address"],
            componentRestrictions: {
                country: countries?.length > 0 ? countries : ["CA"],
            },
        }
    }, [preferredLanguage, countryRestrictions])

    const RequestGoogle = useCallback((searchValue: string, callback: (lists: readonly PlaceType[]) => void) => {
        if (searchValue === "" || searchValue?.length < 3) {
            callback([])
            return undefined
        }
        const request: PlaceRequest = {
            input: searchValue,
            sessionToken,
            ...requestOpts,
        }
        fetch(request, (results?: readonly PlaceType[]) => {
            let newOptions: readonly PlaceType[] = []
            if (results) {
                newOptions = [...newOptions, ...results]
            }
            if (typeof callback === "function") {
                callback(newOptions)
            }
        })
    }, [fetch, requestOpts, sessionToken])

    const handlePlaceDetail = useCallback((place, status, callback) => {
        const data = {
            zip: "",
            city: "",
            province_code: "",
            address: "",
            country: "",
        }
        if (status !== "OK") {
            callback(data)
            return
        }
        for (const result of place.address_components) {
            if (result.types.includes("locality") || result.types.includes("sublocality")) {
                data.city = result.short_name
            }
            if (result.types.includes("administrative_area_level_1")) {
                data.province_code = result.short_name
            }
            if (result.types.includes("postal_code")) {
                data.zip = result.short_name
            }
            if (result.types.includes("country")) {
                data.country = result.short_name
            }
        }
        data.city ??= ""
        data.province_code ??= ""
        data.zip ??= ""
        data.country ??= ""
        data.address = place.name
        
        if (typeof callback === "function") {
            callback(data)
        }
    }, [])

    const FetchPlaceDetail = useCallback((placeId: string, callback) => {
        const opts = {
            placeId,
            fields: ["address_components", "name"],
            language: preferredLanguage,
            sessionToken,
        }
        placesService.current?.getDetails(opts, (place, status) => {
            handlePlaceDetail(place, status, callback)
        })
    }, [sessionToken, preferredLanguage, handlePlaceDetail])

    useEffect(() => {
        const win: any = window
        if (!autocompleteService.current && win?.google?.maps?.places && !sessionToken) {
            setSessionToken(new win.google.maps.places.AutocompleteSessionToken())
            autocompleteService.current = new win.google.maps.places.AutocompleteService()
            const map = new win.google.maps.Map(document.createElement("div"))
            placesService.current = new win.google.maps.places.PlacesService(map)
        }
        if (!autocompleteService.current) {
            return undefined
        }
    }, [RequestGoogle, sessionToken])

    return {
        RequestGoogle,
        FetchPlaceDetail
    }
}

export default useGoogleAddress