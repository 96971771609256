import Icon from "@/components/base/Icon";
import { Box, Button, Typography } from "@mui/material";

const LogoTooltip = ({ logoTooltip }) => {
  return (
    <Typography
      component={"span"}
      sx={{
        color: "action.active",
        fontSize: 12,
        fontWeight: 400,
      }}
    >
      {logoTooltip}
      <Typography
        component={"span"}
        sx={{
          color: "primary.main",
          display: "inline",
          fontSize: 12,
          fontWeight: 400,
          marginLeft: "2px",
        }}
      >
        *
      </Typography>
    </Typography>
  );
};

const FormLogo = ({
  logo,
  openUpload,
  logoTooltip = "",
  addTooltip = "",
  error = false,
  darkBackground = false,
}) => {
  return (
    <Box className="logo-wrapper">
      {logo && (
        <Box
          className="image-frame image-preview"
          onClick={openUpload}
          sx={{ cursor: "pointer" }}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Box
            sx={{
              "& img": {
                ...(darkBackground ? { backgroundColor: "#00041F" } : {}),
              },
            }}
          >
            <img
              src={
                (typeof logo === "string" && logo) ||
                URL.createObjectURL(logo as File)
              }
              alt="Logo"
            />
            <Icon name="image-plus" />
          </Box>
          <LogoTooltip logoTooltip={logoTooltip} />
        </Box>
      )}
      {!logo && (
        <Box
          flexDirection={"column"}
          gap={1}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Button
            className={`image-frame ${error ? "without-logo" : ""}`}
            onClick={openUpload}
          >
            <Icon name="image-plus" />
            <Typography component={"span"}>{addTooltip}</Typography>
          </Button>
          <LogoTooltip logoTooltip={logoTooltip} />
        </Box>
      )}
    </Box>
  );
};

export default FormLogo;
