import { InputBase, InputBaseProps } from "@mui/material"
import { forwardRef } from "react"

const StyledInput = forwardRef((props: Partial<InputBaseProps>, ref) => {
    return <InputBase
    ref={ref}
    {...props}
    sx={{
        backgroundColor: "rgba(172, 182, 229, 0.16)",
        height: "48px",
        padding: "0 8px",
        borderRadius: "8px",
        borderBottom: "1px solid #5B7DD81F",
        ...(props.sx || {})
    }}
    />
})

export default StyledInput