import BaseSelect from "@/components/base/select";
import useProfileInfo from "@/hooks/useProfileInfo";
import useUsersStore from "@/store/users";
import { zodResolver } from "@hookform/resolvers/zod";
import { Stack } from "@mui/material";
import { useEffect, useMemo } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

type UserRole = {
    role: string
}

const FormRole = () => {

    const { t } = useTranslation()
    const { update: updateUsersStore } = useUsersStore()
    const { isSuperAdmin } = useProfileInfo()
    const roles = useMemo(() => {
        return isSuperAdmin ? ["ADMIN"] : ["RESELLER"]
    }, [isSuperAdmin])

    const defaultValue = useMemo(() => {
        return {
            role: roles?.[0] ?? ""
        };
    }, [roles]);

    const schema = useMemo(() => {
        return z.object({
            role: z.string().min(1, t("FORMS.ERROR.FIELD_REQUIRED")),
        });
    }, []);

    const { control, formState } = useForm<UserRole>({
        defaultValues: defaultValue,
        resolver: zodResolver(schema),
        mode: "onTouched",
    });

    const formValue = useWatch({ control })

    useEffect(() => {
        updateUsersStore({
            isFormRoleValid: formState?.isValid,
        })
    }, [formState?.isValid])

    useEffect(() => {
        updateUsersStore({
            formValue: {
                role: formValue?.role
            }
        })
    }, [formValue?.role])

    return <form>
        <Stack gap={1}>
            <BaseSelect
                control={control}
                controlName="role"
                label={t("USERS.ROLE")}
                required
                options={roles.map(role => ({
                    label: t(`FORMS.ROLE_${role}`),
                    value: role,
                }))}
            />
        </Stack>
    </form>
}

export default FormRole