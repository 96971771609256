import env from "@/config/env"
import * as Sentry from "@sentry/react"
import { useEffect } from "react"
import { useNavigationType, useLocation, createRoutesFromChildren, matchRoutes } from "react-router-dom"

class SentryService {
    init() {
        const release = `bo_${env.ENV}@${env.APP_VERSION}`
        Sentry.init({
            dsn: env.SENTRY_DSN,
            release,
            environment: env.ENV,
            integrations: [
                // See docs for support of different versions of variation of react router
                // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
                Sentry.reactRouterV6BrowserTracingIntegration({
                    useEffect,
                    useLocation,
                    useNavigationType,
                    createRoutesFromChildren,
                    matchRoutes
                }),
                Sentry.replayIntegration(),
                Sentry.dedupeIntegration()
            ],
            // Performance Monitoring
            tracesSampleRate: env.SENTRY_TRACE_SAMPLE_RATE, //  Capture 100% of the transactions
            enabled: env.ENV !== "local",
            // Capture Replay for 10% of all sessions,
            // plus for 100% of sessions with an error
            replaysSessionSampleRate: env.SENTRY_REPLAYS_SESSION_SAMPLE_RATE,
            replaysOnErrorSampleRate: env.SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE,
        })
    }
}

export default new SentryService()