import logoDilypse from "@/assets/images/logo-dilypse.png";
import Icon from "@/components/base/Icon";
import BaseInput from "@/components/base/input";
import useStyles from "@/pages/login/styles";
import { UserSessionService } from "@/services";
import UiService from "@/services/ui";
import ResellerStore from "@/store/reseller";
import UserSessionStore from "@/store/user-session";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

const LoginPage = () => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const { classes } = useStyles();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  // const [emailInvalid, setEmailInvalid] = useState(false)
  const [passwordInvalid, setPasswordInvalid] = useState(false);
  const [showMailAdornment, setShowMailAdornment] = useState(false);
  const [showPasswordAdornment, setShowPasswordAdornment] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  //const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  const { resellerLogo, resellerName } = ResellerStore();
  const { isConnected } = UserSessionStore();

  const onSubmit = async (event: any) => {
    event.preventDefault();

    setLoading(true);
    setError("");
    if (!isConnected) {
      setError(t("SYSTEM_ERROR.INVALID_REQUEST"));
      setLoading(false);
    } else {
      const { success, error } = await UserSessionService.login(
        email,
        password
      );

      if (error) {
        setError(t(`${error}`));
      }
      setLoading(false);
      if (success) {
        navigate("/accounts");
      }
    }
  };

  const updateEmail = (email: string) => {
    setEmail(email);
  };

  const updatePassword = (password: string) => {
    if (password.length > 1000 && password.length < 8) {
      setPasswordInvalid(true);
    } else {
      setPasswordInvalid(false);
    }
    setPassword(password);
  };

  useEffect(() => {
    UiService.setState({ title: "LOGIN.TITLE" });
  }, []);

  return (
    <Box className={classes.loginWrapper}>
      {resellerLogo && (
        <img
          data-testid="logo"
          src={resellerLogo || logoDilypse}
          alt={resellerName || "Dilypse"}
        />
      )}
      <Typography data-testid="title" variant="h1">
        {t("LOGIN.TITLE")}
      </Typography>
      <form onSubmit={onSubmit}>
        <BaseInput
          type="email"
          label={t("LABEL.EMAIL")}
          value={email}
          data-testid="email"
          // error={emailInvalid}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            updateEmail(e.target.value.trim())
          }
          showLabelStar={false}
          onFocus={() => setShowMailAdornment(true)}
          onBlur={() => setShowMailAdornment(false)}
          required
          autoComplete="on"
          startAdornment={
            (showMailAdornment || email) && <Icon name="envelope" />
          }
        />

        <BaseInput
          label={t("LABEL.PASSWORD")}
          type="password"
          value={password}
          data-testid="password"
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            updatePassword(e.target.value.trimStart())
          }
          showLabelStar={false}
          onFocus={() => setShowPasswordAdornment(true)}
          onBlur={() => setShowPasswordAdornment(false)}
          required
          autoComplete="on"
          startAdornment={
            (showPasswordAdornment || password) && <Icon name="lock" />
          }
        />
        <Link to={"/forgot-password"}>
          <Typography component={"span"} color="primary">
            {t("LOGIN.FORGOT")}
          </Typography>
        </Link>
        {error && (
          <Typography data-testid="error" component={"span"} className="error">
            {error}
          </Typography>
        )}

        <Button
          variant="contained"
          data-testid="connect"
          type="submit"
          disabled={!password || !email || loading || passwordInvalid}
        >
          {loading && <CircularProgress size={16} />}
          {t("BUTTON.BUTTON_CONNECT")}
        </Button>
      </form>
    </Box>
  );
};

export default LoginPage;
