import { UserApi } from "@/api"
import useNotification from "@/hooks/useNotification"
import UserSessionStore from "@/store/user-session"
import useUsersStore from "@/store/users"
import { useCallback, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"

const useUsersForm = () => {
    const {
        isFormMainInfoValid,
        isFormRoleValid,
        formValue,
        flush: resetUserFormStore,
        update: updateUsersStore,
        previousValue,
        setError
    } = useUsersStore()
    const { profile } = UserSessionStore()
    const [isSubmitting, setIsSubmitting] = useState(false)
    const { notif } = useNotification()
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { userId } = useParams()

    const isFormValid = useMemo(() => {
        return isFormMainInfoValid && isFormRoleValid
    }, [isFormMainInfoValid, isFormRoleValid])

    const handleSubmit = useCallback(async () => {
        if (isFormValid && profile?.uid) {
            setIsSubmitting(true)
            let messageSuccess = t("FORMS.ADD_SUCCESS")
            let messageFailed = t("FORMS.ADD_FAILED")
            try {
                updateUsersStore({ error: {} })
                const request = userId ? UserApi.updateUser : UserApi.createUser
                const body = {
                    firstname: formValue.firstname,
                    lastname: formValue.lastname,
                    email: formValue.email,
                    role: formValue.role
                }
                if (userId) {
                    messageSuccess = t("FORMS.UPDATE_SUCCESS")
                    messageFailed = t("FORMS.UPDATE_FAILED")
                    Object.assign(body, { user_uid: userId })
                }
                const response = await request(profile?.uid, body)
                if (response?.error) {
                    if (response.error === "USER_ALREADY_EXIST") {
                        setError("email", t("FORMS.ERROR.SAME_EMAIL"))
                    }
                    throw new Error("INVALID_REQUEST")
                }
                notif({ message: messageSuccess, type: "SUCCESS" })
                navigate("/users")
                updateUsersStore({ previousValue: body })
            } catch (error) {
                notif({
                    message: messageFailed,
                    type: "ERROR"
                })
            }
            setIsSubmitting(false)
        }
    }, [userId, isFormValid, formValue, profile?.uid, t, notif, navigate])

    const handlePrevious = useCallback(() => {
        updateUsersStore({ previousValue: {
            ...formValue,
            user_uid: previousValue?.user_uid
        } })
        navigate("/users")
    }, [formValue, previousValue])

    const handleCancel = useCallback(() => {
        resetUserFormStore()
        navigate("/users")
    }, [])

    return {
        isFormValid,
        isSubmitting,
        userId,
        resetUserFormStore,
        handleSubmit,
        handlePrevious,
        handleCancel
    }
}

export default useUsersForm