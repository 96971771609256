import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  wrapper: {
    display: "flex",
    gap: 8,
    position: "sticky",
    bottom: 8,
    backgroundColor: theme.palette.common.white,
    padding: 8,
    zIndex: 1,
    flexDirection: "row",
    "@media (min-width: 768px)": {
      gap: 16,
      justifyContent: "flex-end",
      padding: "8px 16px",
      bottom: 16,
    },
    "& button": {
      padding: "8px 22px",
      fontSize: 15,
      fontWeight: 500,
      "@media (min-width: 768px)": {
        width: "100%",
      },
      "&:last-of-type": {
        width: "100%",
      },
    },
    "& .MuiCircularProgress-root": {
      color: theme.palette.text.disabled,
    },
  },
}));

export default useStyles;
