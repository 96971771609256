import { CookiesService } from "@/services";
import { SxProps, Theme } from "@mui/material";
import dayjs from "dayjs";
import "dayjs/locale/en";
import "dayjs/locale/fr";
import { getCountries } from "libphonenumber-js";
import { matchIsValidTel } from "mui-tel-input";
export type ColorType = "primary" | "success" | "warning" | "error";

export const decode64AndParse = (base64: string) => {
  if (!base64) return "";
  return JSON.parse(window.atob(base64));
};

export const isValidURL = (url: string) => {
  const pattern = new RegExp(
    "^(https?:\\/\\/)?([A-Za-z0-9-]+\\.)+[A-Za-z]{2,6}(:\\d{1,5})?(\\/.*)?$", // fragment locator
    "i"
  );
  return pattern.test(url);
};

export const isValidEmail = (email: string) => {
  if (!email || email === undefined) return true;
  const pattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  return pattern.test(email);
};

export const isValidURLSocialNetwork = (url: string, types: string[]) => {
  for (const type of types) {
    if (url.includes(type)) return true;
  }
  return false;
};

export const formatZipByCountry = (value: string, countryCode: string) => {
  if (countryCode === "CA") {
    if (/^[A-Z]\d[A-Z]\d[A-Z]\d$/i.test(value)) {
      return `${value.substring(0, 3)} ${value.substring(3)}`.toUpperCase();
    }
    if (
      value === "" ||
      // length === 1
      /^[A-Z]$/i.test(value) ||
      // length === 2
      /^[A-Z]\d$/i.test(value) ||
      // length === 3
      /^[A-Z]\d[A-Z]$/i.test(value) ||
      // length === 4
      /^[A-Z]\d[A-Z]\s$/i.test(value) ||
      /^[A-Z]\d[A-Z]\d$/i.test(value) ||
      // length === 5
      /^[A-Z]\d[A-Z]\s\d$/i.test(value) ||
      /^[A-Z]\d[A-Z]\d[A-Z]$/i.test(value) ||
      // length === 6
      /^[A-Z]\d[A-Z]\s\d[A-Z]$/i.test(value) ||
      /^[A-Z]\d[A-Z]\d[A-Z]\d$/i.test(value) ||
      // length === 7
      /^[A-Z]\d[A-Z]\s\d[A-Z]\d$/i.test(value)
    ) {
      return value.toUpperCase();
    }
  } else {
    if (value === "") return "";
    if (/^(?:\d{1,5})?$/.test(value)) {
      return value;
    }
  }
};

export const withSxProp = (...sxProps: SxProps<Theme>[]): SxProps<Theme> =>
  sxProps.reduce(
    (prev, currentValue) => [
      ...(Array.isArray(prev) ? prev : [prev]),
      ...(Array.isArray(currentValue) ? currentValue : [currentValue]),
    ],

    [] as SxProps<Theme>
  );

const checkDisplayOfDate = (initDate, lang) => {
  const locale = lang === "en" ? "en" : "fr";
  const formatType = lang === "en" ? "MMM DD, YYYY" : "DD MMM YYYY";
  return `${dayjs(initDate).locale(locale).format(formatType)}`;
};

const checkDisplayOfFullDate = (initDate, lang) => {
  const locale = lang === "en" ? "en" : "fr";
  const formatType = lang === "en" ? "MMMM DD, YYYY" : "DD MMMM YYYY";
  return `${dayjs(initDate).locale(locale).format(formatType)}`;
};

const sortDateException = (list: any) => {
  return list.sort((a, b) =>
    dayjs(a.startDate).isAfter(dayjs(b.startDate)) ? 1 : -1
  );
};

const getColor = (color: ColorType) => {
  switch (color) {
    case "primary":
      return "text.primary";
    case "success":
      return "success.main";
    case "warning":
      return "warning.main";
    case "error":
      return "error.main";
  }
};

const getScoreColor = (score: number) => {
  if (score <= 33) {
    return getColor("error");
  } else if (score > 33 && score <= 66) {
    return getColor("warning");
  } else {
    return getColor("success");
  }
};

const hasOwn = (data: object, key: string): boolean => {
  return key in data;
};

export const urlify = (text: string) => {
  const generatedUrl = CookiesService.get("feedbackUrl") + "/3MvoFeY";

  return text.replace(/@Url|@url/gi, generatedUrl);
};

export const objectToQueryString = (obj) => {
  if (typeof obj === "object" && Object.keys(obj)?.length > 0) {
    const params = new URLSearchParams();
    for (const key in obj) {
      if (hasOwn(obj, key)) {
        params.append(key, obj[key]);
      }
    }
    return params.toString();
  }
  return "";
};

export const encodeBase64 = (base64: string) => {
  if (!base64) return "";
  return window?.btoa(base64);
};

const isObject = (object) => {
  return object != null && typeof object === "object";
};

const isDeepEqual = (object1, object2) => {
  const objKeys1 = Object.keys(object1);
  const objKeys2 = Object.keys(object2);

  if (objKeys1.length !== objKeys2.length) return false;

  for (const key of objKeys1) {
    const value1 = object1[key];
    const value2 = object2[key];

    const isObjects = isObject(value1) && isObject(value2);

    if (
      (isObjects && !isDeepEqual(value1, value2)) ||
      (!isObjects && value1 !== value2)
    ) {
      return false;
    }
  }
  return true;
};

const checkZipIsValid = (zipInput: string, countryCode: string) => {
  if (!zipInput) {
    return true;
  }
  if (countryCode === "CA") {
    return zipInput?.replace(/\s/g, "").length === 6;
  } else {
    return (
      zipInput?.replace(/\s/g, "").length === 5 &&
      !isNaN(Number(zipInput?.replace(/\s/g, "")))
    );
  }
};

const isPhoneValid = (phoneValue: string) => {
  if (
    phoneValue === "" ||
    phoneValue === undefined ||
    /^\+\d{1,4}$/.test(phoneValue)
  ) {
    return true;
  }
  return phoneValue && matchIsValidTel(phoneValue);
};

const includedCountries = getCountries().filter(
  (countryCode) => !["CA", "US", "FR"].includes(countryCode)
);

const keyBy = (array: any[], iteratee: string) => {
  const result: { [iteratee: string]: any } = {};
  for (const element of array) {
    result[element?.[iteratee]] = element;
  }
  return result;
};

export {
  checkDisplayOfDate,
  checkDisplayOfFullDate,
  checkZipIsValid,
  getColor,
  getScoreColor,
  hasOwn,
  includedCountries,
  isDeepEqual,
  isObject,
  isPhoneValid,
  keyBy,
  sortDateException,
};
