import { CompanyApi } from "@/api";
import { LicencesType } from "@/api/companies";
import CompanyStore from "@/store/company";
import UserSessionStore from "@/store/user-session";
import { useEffect, useState } from "react";

const useLicences = () => {
  const [licences, setLicences] = useState<
    { value?: string; label?: string }[]
  >([]);
  const { profile } = UserSessionStore();
  const { updateState } = CompanyStore();

  useEffect(() => {
    const fetchLicences = async () => {
      const resp = await CompanyApi.getLicences(profile?.uid);
      if (resp) {
        setLicences(
          resp.map((item) => {
            return {
              value: item?.uid,
              label: item?.name,
            };
          })
        );
        updateState({
          prices: resp as LicencesType[],
        });
      }
    };
    fetchLicences();
  }, []);

  return {
    licences,
  };
};

export default useLicences;
