import { Paper, SxProps, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"

type PaperBlocOptionalProps = {
    sx: SxProps
}

type PaperBlocProps = {
    title: string
    children: React.ReactNode
} & Partial<PaperBlocOptionalProps>

const PaperBloc = ({ title, sx, children }: PaperBlocProps) => {
    const { t } = useTranslation()
    return <Paper sx={sx}>
        <Typography variant={"h2"} sx={{
            fontSize: 24,
            color: "black",
            fontWeight: 500,
        }}>
            {t(title)}
        </Typography>
        {children}
    </Paper>
}

export default PaperBloc