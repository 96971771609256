import { Add } from "@mui/icons-material"
import { Box, Button, Paper, Stack } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

const TopbarUsers = () => {

    const navigate = useNavigate()
    const { t } = useTranslation()

    return <Paper data-testid="topbar-container" sx={{
        padding: "8px",
        "@media (min-width: 1024px)": {
            padding: "8px 16px",
        },
    }}>
        <Stack
            flexDirection={{
                md: "row",
                sm: "column"
            }}
            justifyContent={"space-between"}
            alignItems={"center"}
            gap={{
                sm: "8px",
                xs: "8px"
            }}
        >
            <Box></Box>
            <Button
                startIcon={<Add />}
                variant="contained"
                data-testid="button-action"
                sx={{
                    width: "100%",
                    "@media (min-width: 1024px)": {
                        width: "fit-content",
                    },
                }}
                onClick={() => navigate("/users/create")}
            >{t("USERS.NEW_USER")}</Button>
        </Stack>
    </Paper>
}

export default TopbarUsers