import { CompanyApi } from "@/api";
import { CreateCompanyPayload } from "@/api/companies";
import CompanyStore from "@/store/company";
import NotificationStore from "@/store/notification";
import i18next from "i18next";
import { NavigateFunction } from "react-router-dom";

class CompanyService {
  onSubmit = async (formValue: CreateCompanyPayload, navigate: NavigateFunction, adminId: string) => {
    const { update: showNotification } = NotificationStore.getState();
    const { updateState: updateCompany } = CompanyStore.getState();
    let resp = null;

    updateCompany({
      isLoading: true,
    });
    const body = { ...formValue };

    if (adminId === undefined) {
      delete body.uid;
      resp = await CompanyApi.createCompany(body);
    } else {
      resp = await CompanyApi.updateCompany(body);
    }

    if (!resp?.error) {
      navigate("/accounts");
      showNotification({
        type: "SUCCESS",
        message: !adminId ? i18next.t("FORMS.CREATE_SUCCESS") : i18next.t("FORMS.UPDATE_SUCCESS"),
        show: true,
      });
    } else {
      if (resp?.error === "USER_ALREADY_EXIST") {
        updateCompany({
          conflictedFields: ["user_email"],
        });
      }
      if (resp?.error === "COMPANY_ALREADY_EXIST") {
        updateCompany({
          conflictedFields: ["name"],
        });
      }
      showNotification({
        type: "ERROR",
        message: !adminId ? i18next.t("FORMS.CREATE_FAILED") : i18next.t("FORMS.UPDATE_FAILED"),
        show: true,
      });
    }
    updateCompany({
      isLoading: false,
    });
  };
}

export default new CompanyService();
