import { ResellerApi } from "@/api";
import { ResellerType } from "@/api/reseller";
import { CookiesService, UiService } from "@/services";
import NotificationStore from "@/store/notification";
import ResellerStore, {
  IResellerSetter,
  IResellerStore,
} from "@/store/reseller";
import i18next from "i18next";
import { NavigateFunction } from "react-router-dom";

class ResellerService {
  getState = (): IResellerStore & IResellerSetter => {
    return ResellerStore.getState();
  };

  setState = (update: Partial<IResellerStore>): void => {
    const { updateState } = this.getState();
    updateState(update);
  };

  getResellerInfos = async () => {
    const reseller = await ResellerApi.fetchResellerInfos(
      window.location.hostname
    );

    CookiesService.set({
      resellerLogoDark: reseller?.vendor?.logoDark || "",
      resellerLogo: reseller?.vendor?.logo || "",
      resellerFavicon: reseller?.vendor?.favicon || "",
      resellerName: reseller?.vendor?.name || "",
    });

    UiService.setState({ favicon: reseller?.vendor?.favicon });

    const resellerState = {
      resellerLogoDark: reseller?.vendor?.logoDark,
      resellerLogo: reseller?.vendor?.logo,
      resellerFavicon: reseller?.vendor?.favicon,
      resellerName: reseller?.vendor?.name,
    };
    ResellerStore.getState().updateState(resellerState);
    return resellerState;
  };

  clearReseller = () => {
    CookiesService.set({
      resellerFavicon: "",
      resellerLogo: "",
      resellerLogoDark: "",
      resellerName: "",
    });
  };

  handleLogo = async (body: ResellerType) => {
    if (body?.logo && typeof body?.logo !== "string") {
      body["file_logo"] = body?.logo;
      body["logo"] = "";
    }
    if (body?.logo_dark && typeof body?.logo_dark !== "string") {
      body["file_logo_dark"] = body?.logo_dark;
      body["logo_dark"] = "";
    }
    if (body?.favicon && typeof body?.favicon !== "string") {
      body["file_favicon"] = body?.favicon;
      body["favicon"] = "";
    }
    return body;
  };

  handleResponse = (resp: any, navigate: NavigateFunction, adminId: string) => {
    const { update: showNotification } = NotificationStore.getState();
    const { updateState } = ResellerStore.getState();

    if (!resp?.error) {
      navigate("/resellers");
      showNotification({
        type: "SUCCESS",
        message: !adminId
          ? i18next.t("FORMS.CREATE_SUCCESS")
          : i18next.t("FORMS.UPDATE_SUCCESS"),
        show: true,
      });
    } else {
      if (resp?.error === "USER_ALREADY_EXIST") {
        updateState({
          conflictedFields: ["user_email"],
        });
      }
      if (resp?.error === "RESELLER_ALREADY_EXIST") {
        updateState({
          conflictedFields: ["name"],
        });
      }

      showNotification({
        type: "ERROR",
        message: !adminId
          ? i18next.t("FORMS.CREATE_FAILED")
          : i18next.t("FORMS.UPDATE_FAILED"),
        show: true,
      });
    }
  };

  onSubmit = async (
    formValue: ResellerType,
    navigate: NavigateFunction,
    adminId: string
  ) => {
    const { updateState } = ResellerStore.getState();

    let resp = null;
    updateState({
      isLoading: true,
      conflictedFields: [],
    });
    let body = { ...formValue };
    delete body?.showUploadFavicon;
    delete body?.showUploadLogoDark;
    delete body?.showUploadLogoWhite;
    if (body?.unlimited) body.license_limit = -1;
    if (/^\+\d{1,4}$/.test(formValue.business_phone)) {
      body.business_phone = "";
    }
    if (/^\+\d{1,4}$/.test(formValue.phone_support)) {
      body.phone_support = "";
    }
    if (adminId === undefined) {
      delete body.uid;
      const logo = await this.handleLogo(body);
      resp = await ResellerApi.createReseller({
        body: {
          ...body,
          ...logo,
        },
      });
    } else {
      const logo = (await this.handleLogo(body)) ?? {};
      resp = await ResellerApi.updateReseller({
        body: {
          ...body,
          ...logo,
        },
      });
    }

    this.handleResponse(resp, navigate, adminId);
    updateState({
      isLoading: false,
    });
  };
}

export default new ResellerService();
