import { CompanyApi } from "@/api";
import useNotification from "@/hooks/useNotification";
import { CookiesService } from "@/services";
import CompanyStore from "@/store/company";
import Cookies from "js-cookie";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

interface CreditType {
  type?: string;
  quantity?: number;
  used?: number;
  remaining?: number;
}

const useTopBarCompanies = () => {
  const { t } = useTranslation();
  const { company_uid } = useParams();
  const [credits, setCredits] = useState<CreditType[]>([]);
  const { notif } = useNotification();
  const { companyData, prices } = CompanyStore();
  const [recharging, setRecharging] = useState<boolean>(false);

  const sms = useMemo(() => {
    return credits?.filter((item) => item.type === "sms_rb")[0]?.remaining ?? 0;
  }, [credits]);
  const prices_credit = useMemo(() => {
    const price = prices?.filter(
      (item) => item?.uid === companyData?.price_uid
    )[0];
    if (!price) return { sms: 0, email: 0 };
    return {
      sms:
        price?.credit?.filter((item) => item.type === "sms_rb")[0]?.quantity ??
        0,
      email:
        price?.credit?.filter((item) => item.type === "email_rb")[0]
          ?.quantity ?? 0,
    };
  }, [companyData, prices]);

  const email = useMemo(() => {
    return (
      credits?.filter((item) => item.type === "email_rb")[0]?.remaining ?? 0
    );
  }, [credits]);

  const getCredit = useCallback(async () => {
    const resp = await CompanyApi.getCredit(company_uid);
    if (resp) {
      setCredits(resp);
    }
  }, []);

  const canRecharge = useMemo(() => {
    return prices_credit?.sms !== sms || prices_credit?.email !== email;
  }, [prices_credit, sms, email]);

  const handleClick = useCallback(async () => {
    setRecharging(true);
    const resp = await CompanyApi.rechargeCredit({
      user_uid: CookiesService.get("user_uid"),
      company_uid,
    });
    if (resp?.error) {
      notif({
        type: "ERROR",
        show: true,
        message: t("COMPANIES.RECHARGE_FAILED"),
      });
    } else {
      getCredit();
    }
    setRecharging(false);
  }, [company_uid]);

  useEffect(() => {
    getCredit();
  }, [company_uid]);

  return {
    recharging,
    canRecharge,
    sms,
    email,
    company_uid,
    handleClick,
  };
};

export default useTopBarCompanies;
