import BaseSelect from "@/components/base/select";
import CompanyStore from "@/store/company";
import { useTranslation } from "react-i18next";
import PaperBlock from "../../base/paper-block";
import useLicences from "./useLicence";

const CompanyLicence = ({ control, formState }) => {
  const { t } = useTranslation();
  const { licences } = useLicences();
  const { disabledFields, isLoading } = CompanyStore();

  return (
    <PaperBlock
      title={t("COMPANIES.CREATE.LICENCE")}
      paperElevation={0}
      paperFullHeight
    >
      <BaseSelect
        label={t("FORMS.LICENCE")}
        required
        control={control}
        options={licences || []}
        formStateError={!!formState?.errors["price_uid"]}
        controlName="price_uid"
        disabled={!!disabledFields?.price_uid || isLoading}
      />
    </PaperBlock>
  );
};
export default CompanyLicence;
