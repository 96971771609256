import { UserApi } from "@/api"
import useUsersForm from "@/components/forms/users/useUsersForm"
import useNotification from "@/hooks/useNotification"
import UiStore from "@/store/ui"
import UserSessionStore from "@/store/user-session"
import useUsersStore from "@/store/users"
import { useCallback, useEffect } from "react"
import { useTranslation } from "react-i18next"

const useEditUser = () => {
    const { profile } = UserSessionStore()
    const { notif } = useNotification()
    const { t } = useTranslation()
    const {
        isFormValid,
        isSubmitting,
        userId,
        handlePrevious,
        handleSubmit,
        handleCancel,
        resetUserFormStore
    } = useUsersForm()
    const { update: updateUsersStore, previousValue } = useUsersStore()
    const { previousPage } = UiStore()

    const fetchUser = useCallback(async () => {
        if (profile?.uid && userId) {
            if (previousValue?.user_uid !== userId) {
                try {
                    const response = await UserApi.fetchUser({ userId, user_uid: profile.uid })
                    if (response?.user_uid) {
                        const defaultValue = {
                            email: response?.email,
                            firstname: response?.firstname,
                            lastname: response?.lastname,
                            user_uid: response?.user_uid
                        }
                        updateUsersStore({
                            previousValue: defaultValue
                        })
                    } else {
                        throw new Error("USER_NOT_FOUND")
                    }
                } catch (error) {
                    notif({
                        message: t("SYSTEM_ERROR.INVALID_REQUEST"),
                        show: true,
                        type: "ERROR"
                    })
                }
            }
        }
    }, [previousValue?.user_uid, userId, profile?.uid, t("SYSTEM_ERROR.INVALID_REQUEST")])

    useEffect(() => {
        if (previousPage === "user-create") {
            resetUserFormStore()
        }
    }, [previousPage])
    
    useEffect(() => {
        (async () => {
            await fetchUser()
        })()
    }, [fetchUser])

    return {
        isSubmitting,
        isFormValid,
        handlePrevious,
        handleCancel,
        handleSubmit
    }
}

export default useEditUser