import Icon from "@/components/base/Icon";
import CustomTooltip from "@/components/base/Tooltip";
import NotificationStore from "@/store/notification";
import { Alert, IconButton, Snackbar } from "@mui/material";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

interface SnackbarSuccess {
  autoHideDuration?: number;
}

const SnackbarSuccess = ({ autoHideDuration = 10000 }: SnackbarSuccess) => {
  const { message, show, update } = NotificationStore();
  const handleOnClose = useCallback(() => {
    update({ message: "", show: false });
  }, [update]);
  const { t } = useTranslation();

  return (
    <Snackbar
      open={show}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      autoHideDuration={autoHideDuration}
      onClose={handleOnClose}
    >
      <Alert
        icon={<Icon name="check-rounded" />}
        sx={{
          backgroundColor: "success.main",
        }}
        className="alert-info"
        action={
          <CustomTooltip title={t("BUTTON.CLOSE")}>
            <IconButton sx={{ color: "white" }} onClick={() => handleOnClose()}>
              <Icon name="x" />
            </IconButton>
          </CustomTooltip>
        }
        severity="success"
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default SnackbarSuccess;
