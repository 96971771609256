import NetworkAutocomplete from "@/components/autocomplete/network-autocomplete";
import BaseInputPhone from "@/components/base/input-phone";
import BaseSelect from "@/components/base/select";
import SelectLang from "@/components/select/select-lang";
import { PHONE_COUNTRIES } from "@/data/phoneCountries";
import CompanyStore from "@/store/company";
import UiStore from "@/store/ui";
import { Stack } from "@mui/material";
import { useMemo } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import FormController from "../../base/form-controller";
import PaperBlock from "../../base/paper-block";
import useMainInfos from "./useMainInfos";

const CompanyMainInfos = ({ formValue, control, formState, handleBusinessPhone, handleMobilePhone }) => {
  const { t } = useTranslation();
  const { preferredLanguage } = UiStore();
  const { disabledFields, isLoading } = CompanyStore();
  const { business } = useMainInfos();
  const langOfInputPhone = useMemo(() => {
    return preferredLanguage?.toLowerCase() || "fr-ca";
  }, [preferredLanguage]);

  return (
    <PaperBlock title={t("COMPANIES.CREATE.INFORMATIONS")} paperElevation={1}>
      <Stack flexDirection={"column"} gap={1} data-testid="informations">
        <FormController fieldLabel={t("FORMS.NAME")} fieldName="name" control={control} disabled={!!disabledFields?.name || isLoading} />
        <Controller
          name="network_name"
          control={control}
          render={({ field, fieldState }) => (
            <NetworkAutocomplete
              label={t("FORMS.NETWORK")}
              defaultValue={field?.value}
              error={!!fieldState?.error}
              hasValue={!!formValue?.uid}
              helperText={fieldState?.error?.message}
              disabled={isLoading}
              inputValueChange={field.onChange}
            />
          )}
        />
        <BaseInputPhone
          label={t("FORMS.BUSINESS_PHONE")}
          onlyCountries={PHONE_COUNTRIES}
          error={!!formState?.errors["business_phone"]}
          lang={langOfInputPhone}
          defaultCountry={"CA"}
          errorLabel={formState?.errors["business_phone"]?.message ?? ""}
          value={formValue?.business_phone}
          name="business_phone"
          onChange={handleBusinessPhone}
          disabled={!!disabledFields?.business_phone || isLoading}
        />

        <BaseInputPhone
          label={t("FORMS.MOBILE_PHONE")}
          error={!!formState?.errors["mobile_phone"]}
          onlyCountries={PHONE_COUNTRIES}
          lang={langOfInputPhone}
          defaultCountry={"CA"}
          errorLabel={formState?.errors["mobile_phone"]?.message ?? ""}
          value={formValue?.mobile_phone}
          name="mobile_phone"
          onChange={handleMobilePhone}
          disabled={!!disabledFields?.mobile_phone || isLoading}
        />

        <BaseSelect
          label={t("FORMS.ACTIVITY")}
          control={control}
          options={business}
          formStateError={!!formState?.errors["business_uid"]}
          helperText={formState?.errors["business_uid"]?.message}
          controlName="business_uid"
          disabled={!!disabledFields?.business_uid || isLoading}
        />

        <SelectLang
          defaultValue={formValue?.lang}
          options={[
            {
              label: t("FORMS.LANGS.FR"),
              value: "fr-CA",
            },
            {
              label: t("FORMS.LANGS.EN"),
              value: "en",
            },
          ]}
          control={control}
          controlName="lang"
          label={t("FORMS.LANG")}
          required
        />
      </Stack>
    </PaperBlock>
  );
};

export default CompanyMainInfos;
