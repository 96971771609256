import BaseSelect from "@/components/base/select";
import CompanyStore from "@/store/company";
import { Checkbox, FormControlLabel, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import FormController from "../../base/form-controller";
import PaperBlock from "../../base/paper-block";
import { useCallback, useState } from "react";
import { UserApi } from "@/api";
import useNotification from "@/hooks/useNotification";
import UiStore from "@/store/ui";
import UserSessionStore from "@/store/user-session";

const CompanyAdmin = ({
  adminId,
  formValue,
  control,
  handleInvitationChange,
}) => {
  const { t } = useTranslation();
  const { disabledFields, isLoading, companyData } = CompanyStore();
  const { profile } = UserSessionStore()
  const [sendingAccess, setSendingAccess] = useState(false);
  const { notif } = useNotification()

  const handleSendAccess = useCallback(async () => {
    if (companyData?.user_email && profile?.uid) {
      setSendingAccess(true)
      try {
        const response = await UserApi.resendAccess({
          email: companyData.user_email,
          lang: companyData.lang,
          user_uid: profile.uid
        })
        if (response?.data === "success") {
          notif({
            message: t("FORMS.SEND_SUCCESS"),
            type: "SUCCESS"
          })
        } else {
          throw new Error("NOT_SENT")
        }
      } catch(error) {
        notif({
          message: t("FORMS.SEND_FAILED"),
          type: "ERROR"
        })
      }
      setSendingAccess(false)
    }
  }, [t, companyData?.lang, companyData?.user_email, profile?.uid])

  return (
    <PaperBlock
      title={t("COMPANIES.CREATE.ADMIN")}
      paperElevation={1}
      buttonText={adminId && t("FORMS.SEND_ACCESS")}
      buttonOnClick={() => adminId && handleSendAccess()}
      buttonLoading={sendingAccess}
    >
      <Stack gap={1} flexDirection={"column"}>
        <Stack
          flexDirection="row"
          gap={1}
          className={"inputs fullWidthContent"}
        >
          <FormController
            control={control}
            fieldLabel={t("FORMS.FIRSTNAME")}
            fieldName="user_firstname"
            disabled={!!disabledFields?.user_firstname || isLoading}
          />
          <FormController
            control={control}
            fieldLabel={t("FORMS.LASTNAME")}
            fieldName="user_lastname"
            disabled={!!disabledFields?.user_lastname || isLoading}
          />
        </Stack>
        <Stack
          flexDirection="row"
          gap={1}
          className={"inputs fullWidthContent"}
        >
          <FormController
            control={control}
            fieldLabel={t("FORMS.EMAIL")}
            fieldName="user_email"
            disabled={!!disabledFields?.user_email || isLoading}
          />

          <BaseSelect
            label={t("FORMS.ROLE")}
            required
            disabled
            options={[
              {
                label: t("FORMS.NETWORK_ROLE"),
                value: "1",
              },
            ]}
            defaultValue={"1"}
            controlName="role"
          />
        </Stack>
      </Stack>

      {!disabledFields?.hasOwnProperty("is_login_sent") && !adminId && (
        <FormControlLabel
          label={t("FORMS.SEND_INVITATION")}
          checked={formValue?.login_send}
          control={<Checkbox onChange={handleInvitationChange} />}
        ></FormControlLabel>
      )}
    </PaperBlock>
  );
};

export default CompanyAdmin;
