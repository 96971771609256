import {
  Checkbox,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  SxProps,
  Typography,
} from "@mui/material";
import { useMemo } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Icon from "../Icon";
import CustomTooltip from "../Tooltip";
import { iconBtnSx } from "../page-container/list-container/title-wrapper/styles";
import { defaultSx, errorSx, inputLabelSx } from "./style";

interface BaseSelectProps {
  id?: string;
  label?: string;
  sx?: SxProps | any;
  defaultValue?: string;
  className?: string;
  required?: boolean;
  controlName?: string;
  control?: any;
  options?: { value?: string; label?: string; prefix?: any }[];
  disabled?: boolean;
  register?: any;
  dataTestId?: string;
  onItemClicked?: (item: any) => void;
  formStateError?: boolean;
  multiSelect?: boolean;
  open?: boolean;
  onSelectionChange?: (selected: any) => void;
  onClearClicked?: () => void;
  selected?: string[];
  mobileWidth?: number;
  helperText?: string;
}

const BaseSelect = ({
  id,
  label,
  sx,
  defaultValue,
  className,
  required = false,
  controlName,
  control,
  options,
  disabled,
  register,
  onItemClicked,
  dataTestId,
  multiSelect,
  formStateError,
  onSelectionChange,
  onClearClicked,
  selected,
  mobileWidth = 768,
  helperText,
  ...rest
}: BaseSelectProps) => {
  const { t } = useTranslation();

  const otherSelectedNumber = useMemo(() => {
    return selected?.length > 1 ? selected.length - 1 : 0;
  }, [selected]);

  return (
    <FormControl
      fullWidth
      className={className}
      variant="filled"
      required={required}
      {...rest}
      {...register}
      disabled={disabled}
      focused={false}
      aria-label="custom-select"
    >
      <InputLabel
        sx={{ ...inputLabelSx(formStateError) }}
        error={formStateError}
        id={id}
      >
        {label}
      </InputLabel>

      {control && (
        <Controller
          render={({ field, fieldState }) => (
            <Select
              defaultValue={defaultValue}
              labelId={id}
              MenuProps={{
                className: "select-menu-wrapper",
                classes: { paper: "select-paper" },
              }}
              error={!!fieldState?.error}
              sx={{
                ...defaultSx({
                  noBottomBorder: false,
                  showLabelStar: true,
                  error: !!fieldState?.error,
                }),
                ...sx,
              }}
              {...field}
            >
              {(!options || options?.length === 0) && (
                <MenuItem value="">
                  <Typography>{t("AUTOCOMPLETE.NO_OPTIONS")}</Typography>
                </MenuItem>
              )}
              {options?.map((option, key) => (
                <MenuItem
                  value={option.value}
                  key={`${String(key)}`}
                  sx={{
                    paddingBottom: "8px",
                    paddingTop: "8px",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                    flexDirection: "row",
                    gap: 1,
                  }}
                  onClick={() => {
                    if (typeof onItemClicked === "function") {
                      onItemClicked(option);
                    }
                  }}
                >
                  {option.prefix && (
                    <Typography component={"span"}>{option.prefix}</Typography>
                  )}
                  <span>{option.label}</span>
                </MenuItem>
              ))}
            </Select>
          )}
          name={controlName || ""}
          control={control}
          defaultValue={defaultValue}
        />
      )}
      {!control && !multiSelect && (
        <Select
          value={defaultValue || ""}
          labelId={id}
          MenuProps={{ className: "select-menu-wrapper" }}
          error={formStateError}
          sx={{
            ...defaultSx({
              noBottomBorder: false,
              showLabelStar: true,
              error: formStateError,
            }),
            ...sx,
          }}
        >
          {(!options || options?.length === 0) && (
            <MenuItem value="" sx={{ justifyContent: "center" }}>
              <Typography component={"span"} sx={{ color: "action.active" }}>
                {t("AUTOCOMPLETE.NO_OPTIONS")}
              </Typography>
            </MenuItem>
          )}
          {options?.map((option, key) => (
            <MenuItem
              value={option.value}
              key={`${String(key)}`}
              sx={{
                paddingBottom: "8px",
                paddingTop: "8px",
              }}
              onClick={() => {
                if (typeof onItemClicked === "function") {
                  onItemClicked(option);
                }
              }}
            >
              {option.label}
            </MenuItem>
          ))}
        </Select>
      )}

      {multiSelect && (
        <Select
          labelId="multiple-checkbox-label"
          id="multiple-checkbox"
          key={"multiple-select-checkbox"}
          multiple
          value={selected || []}
          onChange={onSelectionChange}
          error={formStateError}
          sx={{
            ...defaultSx({
              noBottomBorder: false,
              showLabelStar: true,
              error: formStateError,
            }),
            ...sx,
          }}
          renderValue={(selected: any) => (
            <Stack
              gap={1}
              flexDirection="row"
              sx={{ width: "calc(100% - 75px)" }}
            >
              <Typography className="first-selected" component="span">
                {selected[0]?.label}
              </Typography>
              {otherSelectedNumber > 0 && (
                <Typography
                  width={selected[0]?.label?.length > 20 ? 75 : 30}
                  component="span"
                >
                  +{otherSelectedNumber}
                </Typography>
              )}
            </Stack>
          )}
          endAdornment={
            selected?.length > 0 && (
              <Stack
                flexDirection={"row"}
                marginRight={3}
                className="text-secondary"
                sx={{ position: "absolute", right: "8px" }}
              >
                <CustomTooltip title={t("BUSINESSES.ERASE_TEXT")}>
                  <IconButton
                    sx={iconBtnSx}
                    onClick={onClearClicked}
                    className="text-clear-icon"
                  >
                    <Icon name="close" />
                  </IconButton>
                </CustomTooltip>
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ marginX: "8px", height: "24px", alignSelf: "center" }}
                />
              </Stack>
            )
          }
          MenuProps={{
            className: "select-menu-wrapper",
            sx: {
              width: 390,
              top: -10,
            },
          }}
        >
          {(!options || options?.length === 0) && (
            <MenuItem value="" sx={{ justifyContent: "center" }}>
              <Typography component={"span"} sx={{ color: "action.active" }}>
                {t("AUTOCOMPLETE.NO_OPTIONS")}
              </Typography>
            </MenuItem>
          )}
          {options.map((value: any, k) => (
            <MenuItem
              key={k}
              value={value}
              data-e2e="filter-option"
              sx={{
                "&.Mui-selected": {
                  backgroundColor: "transparent",
                },
              }}
            >
              <Checkbox
                checked={
                  selected.findIndex(
                    (item: any) => item.value === value.value
                  ) > -1
                }
              />
              <ListItemText primary={value.label} sx={{ textWrap: "pretty" }} />
            </MenuItem>
          ))}
        </Select>
      )}
      {helperText && (
        <Typography
          sx={{
            ...errorSx,
          }}
        >
          {helperText}
        </Typography>
      )}
    </FormControl>
  );
};

export default BaseSelect;
