import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  menuWrapper: {
    gap: 16,
    "&.box-init": {
      display: "flex",
      flexDirection: "column",
      boxShadow: "none",
      padding: 10,
      position: "fixed",
      width: "80%",
      height: "100%",
      left: "-600%",
      top: 0,
      transition: "left .6s ease-in-out",
      backgroundColor: theme.palette.secondary.main,
      zIndex: 2,
      boxSizing: "border-box",
      color: "white",
      "@media (min-width: 768px)": {
        left: 0,
        width: "251px",
      },
    },

    "& .MuiDrawer-paper": {
      "@media (min-width: 768px)": {
        left: 0,
      },
    },
    "& .items-wrapper": {
      display: "flex",
      flexDirection: "column",
      gap: 4,
    },
    "& .menu-item": {
      color: "white",
      display: "flex",
      gap: 8,
      justifyContent: "flex-start",
      padding: "10px",
      height: "44px",
      width: "231px",
      "&.parent": {
        justifyContent: "space-between",
      },
      "&:hover": { backgroundColor: "transparent" },
      "& .MuiTypography-root": {
        fontSize: 16,
        color: "inherit",
      },
      "&.active": {
        backgroundColor: theme.palette.text.disabled,
        width: "calc(100% - 22px)",
      },
    },

    "& .bloc-menu": {
      display: "flex",
      flexDirection: "column",
      gap: 8,
      height: "100%",
      justifyContent: "space-between",
      overflow: "hidden",
      width: "100%",
    },
    "& .menu-previous": {
      display: "flex",
      gap: 8,
      alignItems: "center",
      justifyContent: "flex-start",
      alignSelf: "stretch",
      "& .MuiTypography-root": {
        fontSize: 14,
        color: "#8f919c",
      },
      padding: 0,
    },
    "& .logo-wrapper": {
      display: "flex",
      justifyContent: "space-between",
      paddingTop: 4,
      "& img": {
        width: 162,
        height: 46,
        objectFit: "contain",
        objectPosition: "left",
      },
      "& button": {
        height: 40,
        width: 40,
        color: theme.palette.primary.main,
        "@media (min-width: 768px)": {
          display: "none",
        },
      },
    },
    "&.show": {
      left: 0,
    },
    "& button": {
      boxShadow: "none",
    },
    "& .profile-wrapper": {
      display: "flex",
      gap: 8,
      alignItems: "center",
      width: "calc(100% - 8px)",
      "& .profile": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        overflow: "hidden",
        paddingRight: "16px",
        "& .MuiTypography-subtitle2": {
          fontSize: 12,
          color: "white",
          textOverflow: "ellipsis",
          overflow: "hidden",
        },
        "& .MuiTypography-subtitle1": {
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          overflow: "hidden",
          color: "white",
        },
      },
    },
    "& .top": {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      gap: 16,
    },
    "& .bottom": {
      display: "flex",
      flexDirection: "column",
      gap: 8,
      "& .menu-item": {
        display: "flex",
        gap: 8,
        fontSize: 16,
        width: "100%",
        justifyContent: "flex-start",
        "&.parent": {
          justifyContent: "space-between",
        },
        "& .MuiTypography-root": {
          color: "white",
        },
      },
      "& .MuiTypography-root": {
        color: "rgba(104, 115, 141, 0.64)",
        textAlign: "center",
        fontSize: 16,
      },
      "& .version": {
        fontSize: 14,
      },
    },
  },
  menuDrawer: {
    display: "flex",
    gap: 16,
    flexDirection: "column",
    boxShadow: "none",
    height: "100%",
    position: "relative",
    top: 0,
    left: "800px",
    width: "100%",
    transition: "left .5s cubic-bezier(0.820, 0.085, 0.395, 0.895)",
    backgroundColor: theme.palette.secondary.main,
    boxSizing: "border-box",
    zIndex: 2,
    "& .MuiDrawer-paper": {
      gap: 8,
    },
    "@media (min-width: 768px)": {
      zIndex: 2,
    },
    "&.show": {
      left: 0,
      zIndex: 3,
    },
  },
  menuBackdrop: {
    backgroundColor: "rgba(53, 56, 75, 0.42)",
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100vh",
    zIndex: -1,
    visibility: "hidden",
    transition: "opacity .6s ease-in-out",
    opacity: 0,
    "@media (max-width: 768px)": {
      "&.show": {
        visibility: "visible",
        overflow: "hidden",
        zIndex: 2,
        opacity: 1,
      },
    },
  },

  menuStack: {
    position: "absolute",
    bottom: 0,
    overflow: "hidden",
    height: "100%",
    "@media (max-width: 768px)": {
      width: "80%",
    },
  },
}));

export default useStyles;
