import {
  Box,
  SxProps,
  Table,
  TableCell,
  TableContainer,
  TableFooter,
  TablePagination,
  TableRow,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import TablePaginationActions from "../pagination-action";
import TableBodyWrapper from "../table-body-wrapper";
import TableHeadCellWrapper from "../table-head-cell-wrapper";
import TableHeadSort from "../table-head-sort";
import TableHeadWrapper from "../table-head-wrapper";

type Pagination = {
  rowsPerPage: number;
  page: number;
  totalCount: number;
};

type CustomTableOptionalProps = {
  showHeader: boolean;
  pagination: Pagination;
  activeSort: {
    sort_by: string;
    sort_order: "asc" | "desc";
  };
  onPageChange: (newPage: number) => void;
  onSort: (columnId: string, sorted: boolean) => void;
};

type CustomTableProps = {
  columns: any;
  rows: any[];
} & Partial<CustomTableOptionalProps>;

type ColumnOptionalProps = {
  width: number | string;
  sortable: boolean;
  multiline: boolean;
  hidden: boolean;
  component: ({ column, row }) => React.ReactNode;
};

export type Column = Partial<ColumnOptionalProps> & {
  id: string;
  label: string;
};

const singleLineSx: SxProps = {
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  overflow: "hidden",
};

const CustomTable = ({
  columns,
  rows,
  pagination,
  onPageChange,
  onSort,
  activeSort,
  showHeader = true,
}: CustomTableProps) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <TableContainer>
        <Table aria-label="table">
          {showHeader && (
            <TableHeadWrapper>
              {columns
                .filter((item) => !item.hidden)
                .map((column) => (
                  <TableHeadCellWrapper
                    key={column.id}
                    style={{ width: column.width }}
                  >
                    {column.sortable ? (
                      <TableHeadSort
                        label={column.label}
                        column={column}
                        onSort={onSort}
                        activeSort={activeSort}
                      />
                    ) : (
                      column.label
                    )}
                  </TableHeadCellWrapper>
                ))}
            </TableHeadWrapper>
          )}
          <TableBodyWrapper>
            {rows.map((row, index) => {
              return (
                <TableRow
                  tabIndex={-1}
                  key={`${index}`}
                  role={`table-row-${index}`}
                  sx={{ position: "relative" }}
                >
                  {columns
                    .filter((item) => !item.hidden)
                    .map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell
                          key={column.id}
                          sx={{
                            maxWidth: column.width || "100%",
                            ...(!column.multiline && singleLineSx),
                          }}
                        >
                          {column.component
                            ? column.component({ column, row })
                            : value}
                        </TableCell>
                      );
                    })}
                </TableRow>
              );
            })}
          </TableBodyWrapper>
          {pagination && (
            <TableFooter>
              <TableRow>
                <TablePagination
                  labelDisplayedRows={({ from, to, count }) => {
                    return (
                      "" +
                      from +
                      "-" +
                      to +
                      " " +
                      t("PAGINATION.PAGING") +
                      " " +
                      count
                    );
                  }}
                  count={pagination?.totalCount}
                  rowsPerPage={pagination?.rowsPerPage}
                  page={pagination?.page}
                  onPageChange={(__, newPage: number) => onPageChange(newPage)}
                  rowsPerPageOptions={[]}
                  ActionsComponent={TablePaginationActions}
                  sx={{ border: "none" }}
                />
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </TableContainer>
    </Box>
  );
};

export default CustomTable;
