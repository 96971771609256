import { TextField, TextFieldProps, Typography } from "@mui/material";
import { ReactNode, forwardRef } from "react";
import { defaultSx } from "./style";
interface BaseInputProps {
  sx?: any,
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  hiddenLabel?: boolean;
  showLabelStar?: boolean;
  max?: number;
  onFocus?: () => void;
  onBlur?: () => void;
}

const BaseInput = forwardRef((props: TextFieldProps & BaseInputProps, ref: any) => {
  const {
    label,
    error,
    type = "text",
    startAdornment,
    endAdornment,
    showLabelStar = true,
    hiddenLabel = false,
    max,
    sx = {},
    ...rest
  } = props;

  return (
    <TextField
      hiddenLabel={hiddenLabel}
      variant="filled"
      label={
        !hiddenLabel && (
          <Typography
            component="span"
            sx={{
              color: "action.active",
              ".Mui-disabled &": { color: "text.disabled" },
              ".Mui-error &": { color: "error.main" },
            }}
          >
            {label}
          </Typography>
        )
      }
      error={!!error}
      type={type}
      sx={{
        ...defaultSx({ error, showLabelStar }),
        ...sx
      }}
      InputProps={{
        startAdornment,
        endAdornment,
        
      }}
      inputProps={{
        maxLength: max || 255
      }}
      ref={ref}
      {...rest}
    />
  );
});

export default BaseInput;
