import { ApiService, UserApi } from "@/api";
import { LoginData } from "@/api/user";
import { CookiesService, ResellerService } from "@/services";
import ResellerStore from "@/store/reseller";
import UserSessionStore, {
  IUserSessionSetter,
  IUserSessionStore,
} from "@/store/user-session";

class UserSessionService {

  clearSession = () => {
    CookiesService.flush()
    ResellerStore.getState().updateState({ resellerFetched: false })
  };

  updateSession = (loginResponse: LoginData) => {
    UserSessionStore.getState().updateState({
      role: loginResponse?.user?.role,
      token: loginResponse.tokens?.token,
      refreshToken: loginResponse.tokens?.refresh_token,
      isLoggedIn: true,
      profile: {
        email: loginResponse?.user?.email,
        firstname: loginResponse?.user?.firstname,
        lastname: loginResponse?.user?.lastname,
        uid: loginResponse?.user?.uid,
        is_super_admin: loginResponse?.user?.is_super_admin ?? false,
      },
    });
    CookiesService.set({
      firstname: loginResponse?.user?.firstname,
      lastname: loginResponse?.user?.lastname,
      token: loginResponse?.tokens?.token,
      refreshToken: loginResponse?.tokens?.refresh_token,
      role: loginResponse?.user?.role,
      user_uid: loginResponse?.user?.uid,
      email: loginResponse?.user?.email,
      is_super_admin: loginResponse?.user?.is_super_admin ?? false,
      is_switch_account: loginResponse?.user?.is_switch_account ?? false,
      resellerUid: loginResponse?.vendor?.uid
    })
    ApiService.setToken(loginResponse?.tokens?.token, loginResponse?.tokens?.refresh_token)
  };

  login = async (email: string, password: string) => {
    const loginResponse = await UserApi.connectUser(email, password);
    if (loginResponse?.error) {
      this.clearSession();
      return {
        success: false,
        error: loginResponse.error ?? "SYSTEM_ERROR.INVALID_REQUEST",
      };
    } else {
      if (loginResponse?.user) {
        this.updateSession(loginResponse)
        ResellerService.setState({ resellerUid: loginResponse?.vendor?.uid });
        return { success: true };
      }
      this.clearSession();
      return { success: false };
    }
  };

  logout = async () => {
    const { is_switch_account } = CookiesService.getAll()
    const is_switch = UserSessionStore.getState()?.profile?.is_switch_account || (is_switch_account ?? false);
    if (is_switch) {
      await this.promoteUser({
        user_uid: UserSessionStore.getState()?.profile?.uid,
        type: "demote",
      });
      return false;
    } else {
      this.clearSession();
      UserSessionStore.getState().flush();
      await ApiService.invalidateToken()
      return true;
    }
  };

  resetPassword = async (email: string, lang: string) => {
    return await UserApi.resetPassword(email, lang);
  };

  initPassword = async ({ email, uid, password, confirm_password }) => {
    return await UserApi.initPassword({
      email,
      uid,
      password,
      confirm_password,
    });
  };

  promoteUser = async ({
    user_uid,
    reseller_uid,
    type = "promote",
  }: {
    user_uid: string;
    reseller_uid?: string;
    type?: string;
  }) => {
    let promoteResp = null;
    if (type === "promote") {
      promoteResp = await UserApi.promoteUser({ user_uid, reseller_uid });
    } else {
      promoteResp = await UserApi.demoteUser({ user_uid });
    }
    if (!promoteResp?.error && promoteResp?.user) {
      this.clearSession();
      this.updateSession(promoteResp);

      return true;
    }

    return false;
  };

  getState = (): IUserSessionStore & IUserSessionSetter => {
    return UserSessionStore.getState();
  };

  setState = (update: Partial<IUserSessionStore>): void => {
    const { updateState } = this.getState();
    updateState(update);
  };
}

export default new UserSessionService();
