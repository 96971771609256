import { ThemeOptions } from "@mui/material";

const lightTheme: ThemeOptions = {
  palette: {
    primary: {
      main: "#0180FF",
      dark: "#0059B2",
      light: "rgba(1, 128, 255, 0.16)",
    },
    secondary: {
      main: "#00041F",
    },
    success: {
      main: "#03CC6C",
    },
    error: {
      main: "#F46259",
      light: "rgba(244, 98, 89, 0.12)",
    },
    warning: {
      main: "#FFC431",
    },
    action: {
      active: "#68738D",
      disabled: "rgba(104, 115, 141, 0.42)",
    },
    info: {
      main: "#2EAFF7",
    },
    background: {
      default: "rgb(244, 245, 250)",
    },
    divider: "rgba(91, 125, 216, 0.16)",
    text: {
      disabled: "rgba(104, 115, 141, 0.64)",
      primary: "#00041F",
      secondary: "rgba(104, 115, 141, 1)",
    },
    common: {
      white: "rgb(255, 255, 255)",
    },
  },
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          color: "primary.main",
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: "rgba(53, 56, 75, 0.42)",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          "&.primary": {
            color: "#00041F",
          },
          "&.success": {
            color: "#03CC6C",
          },
          "&.error": {
            color: "#F46259",
          },
          "&.MuiTypography-h3": {
            fontSize: "20px",
            fontWeight: 500,
            lineHeight: "32px",
            letterSpacing: "0.15000000596046448px",
          },
        },
        h1: {
          fontSize: "34px",
          fontWeight: 500,
          lineHeight: "41.99px",
          letterSpacing: "0.25px",
        },
        h3: {
          fontSize: "20px",
          fontWeight: 500,
          lineHeight: "32px",
          letterSpacing: "0.15000000596046448px",
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          "@media (max-width: 768px)": {
            padding: 0,
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          padding: 16,
          display: "flex",
          flexDirection: "column",
          boxShadow: "rgba(8, 0, 31, 0.18) 0px 1px 2px -1px",
          // boxShadow: "0px 1px 1px 0px rgba(0, 4, 31, 0.08), 0px 1px 4px 0px rgba(8, 0, 31, 0.12)",
          boxSizing: "border-box",
          height: "auto",
          gap: 16,
          borderRadius: 16,
          "@media (max-width: 768px)": {
            gap: 8,
            padding: 8,
          },
        },
        elevation2: {
          border: "2px solid var(--Divider, rgba(91, 125, 216, 0.16))",
          boxShadow: "none",
          display: "flex",
          flexDirection: "column",
          padding: 16,
          gap: 16,
          borderRadius: 8,
          justifyContent: "start",
          boxSizing: "border-box",
          height: "auto",
          "@media (max-width: 768px)": {
            gap: 8,
            padding: 8,
          },
          "& p": {
            color: "#68738D",
          },
        },
        elevation6: {
          boxShadow:
            "0px 6px 10px 0px rgba(0, 4, 31, 0.16), 0px 1px 18px 0px rgba(0, 4, 31, 0.16)",
          "@media (max-width: 768px)": {
            padding: 8,
          },
        },
        elevation8: {
          boxShadow:
            "0px 8px 10px 1px rgba(0, 4, 31, 0.16), 0px 3px 14px 2px rgba(0, 4, 31, 0.16)",
        },
      },
    },
    MuiModal: {
      defaultProps: { disableRestoreFocus: true },
    },
    MuiPopover: {
      styleOverrides: {
        root: {
          "& .MuiPopover-paper": {
            padding: 8,
          },
        },
      },
      defaultProps: { disableRestoreFocus: true },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow:
            "0px 2px 2px 0px rgba(0, 4, 31, 0.08), 0px 1px 5px 0px rgba(0, 4, 31, 0.08)",
          "&:disabled": {
            color: "rgba(104, 115, 141, 0.42) !important",
            backgroundColor: "rgba(104, 115, 141, 0.32)",
            "& *": {
              color: "rgba(104, 115, 141, 0.42) !important",
            },
          },
          "&.MuiButton-containedSuccess *": {
            color: "white",
          },
          height: "42px",
          padding: "8px 22px 8px 22px",
          borderRadius: "8px",
        },
        containedPrimary: {
          backgroundColor: "#0180FF",
          "& *": { color: "white" },
        },
        textSecondary: {
          backgroundColor: "rgba(1, 128, 255, 0.12)",
          color: "#0180FF",
          border: "1px solid transparent",
          boxShadow: "none",
          "&:hover": {
            backgroundColor: "rgba(1, 128, 255, 0.12)",
            // border: "1px solid rgba(33, 150, 243, 0.50)",
          },
        },
        containedSecondary: {
          color: "#0180FF",
          boxShadow: "none",
          "&:hover": {
            backgroundColor: "rgba(1, 128, 255, 0.12)",
            border: "1px solid rgba(33, 150, 243, 0.50)",
            boxShadow: "none",
          },
        },
        textPrimary: {
          boxShadow: "none",
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          "&.Mui-disabled input": {
            WebkitTextFillColor: "rgba(104, 115, 141, 0.64)",
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: "1px solid #E4EAFA",
        },
        head: {
          backgroundColor: "transparent",
          height: "56px",
          padding: "0 16px",
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        colorPrimary: {
          "& svg": {
            color: "#68738D",
          },
          "&.Mui-checked svg": {
            color: "#0180FF",
          },
        },
      },
    },
    MuiFormGroup: {
      styleOverrides: {
        root: {
          "& .MuiFormControlLabel-root": {
            margin: 0,
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        outlinedSecondary: {
          border: "1px solid rgba(91, 125, 216, 0.16)",
          padding: 4,
          "& svg": {
            color: "#0180FF",
          },
          "& .MuiChip-label": {
            padding: "3px 6px",
            color: "#00041F",
            fontSize: 13,
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: "rgba(172, 182, 229, 0.16)",
          "& input": {
            "&::placeholder, &::-ms-input-placeholder": {
              color: "#68738D",
              opacity: 1,
            },
          },
          "& fieldset": {
            border: "none",
            borderBottom: "1px solid #5B7DD81F",
          },
          "&:hover, &.Mui-focused": {
            "& fieldset": {
              borderBottom: "1px solid #5B7DD81F",
            },
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        standardInfo: {
          boxShadow: "none",
        },
        standardError: {
          backgroundColor: "#FDEDED",
          color: "#5F2120",
        },
        icon: {
          marginRight: 0,
        },
      },
    },
    MuiAlertTitle: {
      styleOverrides: {
        root: {
          color: "inherit",
          fontFamily: "Poppins Medium",
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          padding: 16,
          backgroundColor: "#fff",
          boxShadow: "rgba(8, 0, 31, 0.18) 0px 1px 2px -1px",
          boxSizing: "border-box",
          height: "auto",
          gap: 16,
          borderRadius: 16,
          "@media (max-width: 768px)": {
            gap: 8,
            padding: 8,
          },
        },
        container: {
          maxWidth: "100%",
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          borderRadius: 16,
          boxShadow: "rgba(8, 0, 31, 0.18) 0px 1px 2px -1px",
          boxSizing: "border-box",
          padding: 8,
          backgroundColor: "#FFF",
          display: "flex",
          gap: 16,
          "@media (min-width: 768px)": {
            padding: 16,
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          backgroundColor: "rgba(172, 182, 229, 0.16)",
          borderBottom: "1px solid #5B7DD81F",
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          "& .MuiDialogTitle-root, & .MuiDialogContent-root": {
            padding: 0,
            position: "relative",
          },
          "& .MuiDialogTitle-root": {
            fontSize: "24px",
            lineHeight: "32.02px",
            fontWeight: 500,
          },
          "& .MuiDialogActions-root": {
            justifyContent: "center",
            padding: 0,
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          boxShadow:
            "0px 8px 10px 1px rgba(0, 4, 31, 0.16),0px 3px 14px 2px rgba(0, 4, 31, 0.16)",
          padding: 0,
        },
      },
    },
  },
};

export default lightTheme;
