import { SxProps, Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const modalStyle: SxProps<Theme> = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "calc(100% - 48px)",
  width: 494,
  bgcolor: "background.paper",
  p: 2,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: 2,
  "@media (max-width: 768px)": {
    // maxWidth: 376,
    width: "calc(100% - 5%)",
    padding: "16px",
  },
  "& .title-wrapper": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: 1,
    "& h2": {
      fontSize: 24,
      fontWeight: 500,
      textAlign: "center",
    },
    "& p": {
      color: "action.active",
      textAlign: "center",
    },
    "& button": {
      position: "absolute",
      top: 8,
      right: 8,
      color: "primary.main",
    },
  },
  "& .action-wrapper": {
    display: "flex",
    gap: 2,
    flexDirection: "column",
    width: "100%",
    "& button": {
      padding: "8px 22px",
      fontSize: 15,
      fontWeight: 500,
      width: "100%",
      "&:first-of-type": {
        backgroundColor: "rgba(1, 128, 255, 0.12)",
        color: "primary.main",
      },
    },
    "@media (min-width: 768px)": {
      flexDirection: "row",
    },
  },
};

const useStyles = makeStyles()(() => ({
  usersWrapper: {},
}));

export default useStyles;
export { modalStyle };
