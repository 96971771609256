import { TableHead, TableRow } from "@mui/material"

type TableHeadWrapperProps = {
    children: React.ReactNode
}

const TableHeadWrapper = ({ children }: TableHeadWrapperProps) => {
    return <TableHead sx={{
        ".MuiTableCell-root": {
            backgroundColor: "#F4F5FA",
            borderBottom: "none",
            position: "relative",
            overflow: "hidden",
            "&::after": {
                content: "''",
                position: "absolute",
                height: "1px",
                width: "100%",
                backgroundColor: "#5B7DD829",
                bottom: "0",
                left: "0",
            },
            "&:first-of-type": {
                borderRadius: "8px 0px 0px 8px",
            },
            "&:last-child": {
                borderRadius: "0px 8px 8px 0px",
            },
        },
        ".MuiTypography-root": {
            fontSize: "14px",
            fontWeight: 500,
            lineHeight: "24px",
            letterSpacing: "0.17px",
            textAlign: "left"
        }
    }}>
        <TableRow>
            {children}
        </TableRow>
    </TableHead >
}

export default TableHeadWrapper