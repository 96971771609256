import { CompanyApi } from "@/api";
import Icon from "@/components/base/Icon";
import PopoverButton from "@/components/base/buttons/popover-button";
import DialogConfirmation from "@/components/base/dialog/dialog-confirmation";
import env from "@/config/env";
import useNotification from "@/hooks/useNotification";
import CompanyStore from "@/store/company";
import UserSessionStore from "@/store/user-session";
import { Button, CircularProgress, Paper, Stack, Typography } from "@mui/material";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useTopBarCompanies from "./useTopBarCompanies";

const ButtonIcon = ({ iconName, text, disabled = false, loading = false, onClick }) => {
  return (
    <Button
      startIcon={!loading ? <Icon name={iconName} /> : <CircularProgress size={16} sx={{ marginRight: "8px" }} />}
      variant="contained"
      data-testid="button-action"
      disabled={disabled}
      sx={{
        width: "100%",
        "@media (min-width: 1024px)": {
          width: "fit-content",
        },
      }}
      onClick={onClick}
    >
      {text}
    </Button>
  );
};

const CreditsIcon = ({ number, icon }) => {
  return (
    <Stack flexDirection={"row"} alignItems="center" gap={0.5} data-testid="sms">
      <Icon name={icon} />
      <Typography component={"span"}>{number}</Typography>
    </Stack>
  );
};

const TopBarCompanies = () => {
  const { t } = useTranslation();
  const { canRecharge, recharging, sms, email, company_uid, handleClick } = useTopBarCompanies();
  const { profile } = UserSessionStore();
  const { companyData } = CompanyStore();
  const navigate = useNavigate();
  const { notif } = useNotification();
  const [deleting, setDeleting] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const deleteCompany = useCallback(async () => {
    setDeleting(true);
    const resp = await CompanyApi.deleteCompany({
      company_uid: companyData?.uid,
    });
    if (!resp?.error) {
      notif({
        show: true,
        type: "SUCCESS",
        message: t("COMPANIES.DELETE_SUCCESS"),
      });
      navigate("/accounts");
    } else {
      notif({
        show: true,
        type: "ERROR",
        message: t("COMPANIES.DELETE_FAILED"),
      });
    }
    setDeleting(false);
  }, [companyData]);

  return (
    <Paper>
      <Stack flexDirection={"row"}>
        <Stack flexDirection={"row"} gap={2} sx={{ width: "100%" }}>
          <ButtonIcon
            text={t("BUSINESSES.RECHARGE_CREDITS")}
            iconName="refreshCircle"
            disabled={!canRecharge}
            loading={recharging}
            onClick={handleClick}
          />
          <Stack flexDirection={"row"} gap={2} justifyContent={"center"} alignItems={"center"}>
            <CreditsIcon number={sms ?? 0} icon="messageText" />
            <CreditsIcon number={email ?? 0} icon="mail" />
          </Stack>
        </Stack>
        <Stack flexDirection={"row"} gap={2} sx={{ width: "100%" }} justifyContent="flex-end">
          <ButtonIcon
            text={t("BUSINESSES.CONNEXION")}
            iconName="redirect"
            onClick={() =>
              window.open(`${companyData?.front_base_url}?user_uid=${profile?.uid}&company_uid=${company_uid}&personae_token=`, "_blank")
            }
          />

          <PopoverButton
            sx={{
              padding: 0,
            }}
          >
            <Stack
              gap={"18px"}
              flexDirection={"column"}
              style={{
                width: "fit-content",
              }}
              justifyContent={"flex-start"}
              alignContent={"flex-start"}
              alignItems={"flex-start"}
            >
              {/* <Button startIcon={<Icon name="reactivate-status" />}>
                {t("BUSINESSES.ENABLE_ACCESS")}
              </Button> */}
              <Button
                startIcon={<Icon name="trash" />}
                sx={{
                  color: "error.main",
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  fontSize: 15,
                  minWidth: "100%",
                }}
                onClick={() => setIsDeleting(true)}
                disabled={deleting}
              >
                {t("BUSINESSES.DELETE")}
              </Button>
            </Stack>
          </PopoverButton>
        </Stack>
      </Stack>

      <DialogConfirmation
        title={t("COMPANIES.DELETE_TITLE")}
        textContent={t("COMPANIES.DELETE_TEXT")}
        buttonText={t("FORMS.DELETE")}
        loading={deleting}
        open={isDeleting}
        onSubmit={deleteCompany}
        onCancel={() => setIsDeleting(false)}
      />
    </Paper>
  );
};

export default TopBarCompanies;
