import { UiService } from "@/services"
import UiStore from "@/store/ui"
import { useEffect, useState } from "react"

type UseUiPageSessionOptionalProps = {
    page: string
}

type UseUiPageSessionProps = {
    title: string
} & Partial<UseUiPageSessionOptionalProps>

const useUiPageSession = ({ title, page }: UseUiPageSessionProps) => {
    const { currentPage, updateState: updateUiStore } = UiStore()
    const [open, setOpen] = useState<boolean>(false)

    const handleDrawerClose = () => {
        setOpen(false)
    }

    useEffect(() => {
        updateUiStore({ currentPage: page })
    }, [page])

    useEffect(() => {
        UiService.setState({ title })
        return () => {
            updateUiStore({ previousPage: currentPage })
        }
    }, [title, currentPage])


    return {
        handleDrawerClose,
        open,
    }
}

export default useUiPageSession
